import { Triangle } from 'three'
import { Units } from '../constants'
import { triangulate } from './triangulation'

export const FEET_IN_METER = 3.28084

/**
 * @param {import('three').Vector3[]} points
 * @param {String} unit
 * @param {Number} scale
 * @returns {number}
 */
export const getDistance = (points, unit = Units.Feet, scale = 1.0) => {
  const distanceInMeters = points.reduce(
    (distance, point, index) => distance + (index === points.length - 1 ? 0 : point.distanceTo(points[index + 1])),
    0
  )

  if (unit === Units.Meters) {
    return distanceInMeters * scale
  }

  const distanceInFeets = distanceInMeters * FEET_IN_METER

  return distanceInFeets * scale
}

/**
 * @param {import('three').Points[]} points
 * @param {String} unit
 * @param {Number} scale
 * @returns {number}
 */
export const getSquare = (points, unit = Units.Feet, scale = 1.0) => {
  const triangles = triangulate(points)

  let squareInMeters = 0
  for (let i = 0; i < triangles.length; i += 3) {
    squareInMeters += new Triangle(points[triangles[i]], points[triangles[i + 1]], points[triangles[i + 2]]).getArea()
  }

  if (unit === Units.Meters) {
    return squareInMeters * scale * scale
  }

  const squareInFeets = squareInMeters * FEET_IN_METER * FEET_IN_METER

  return squareInFeets * scale * scale
}
