import QueryString from 'qs'

export function _getAiClassesRequest() {
  return {
    url: `api/ai/objects`,
    method: 'get',
  }
}

export function _getAiObjectsForVqaPolicy() {
  return {
    url: `api/ai/objects/vqaPolicy`,
    method: 'get',
  }
}

export function _getUniqueAiObject({ uniqueAIObjectId }) {
  return {
    url: `api/ai/uniqueObjects/${uniqueAIObjectId}`,
    method: 'get',
  }
}

const aiRequests = {
  getAiObjectsRequest: _getAiClassesRequest,
  getAiObjectsForVqaPolicyRequest: _getAiObjectsForVqaPolicy,
  getAiObjectsForTagsRequest: _getAiObjectsForVqaPolicy,

  getAiObjectRequest: ({ aiObjectId }) => ({
    url: `api/ai/objects/${aiObjectId}`,
    method: 'get',
  }),

  getAiObjectsForProjectTrainingRequest: (contentId) => ({
    url: `api/ai/objects/content/${contentId}`,
    method: 'get',
  }),
  getAiObjectsWithStatisticsRequest: () => ({
    url: `api/ai/objects/statistics`,
    method: 'get',
  }),
  getUniqueAiObjectsRequest: ({ aiObjectId, contentId }) => ({
    url: `api/ai/uniqueObjects/${aiObjectId}/${contentId}`,
    method: 'get',
  }),
  searchUniqueAiObjectsRequest: ({ uniqueAIObjectId, contentId, type, searchText, isParent, skip = 0, take = 50 }) => ({
    url: `api/ai/uniqueObjects/${uniqueAIObjectId}/${contentId}/connection/search`,
    method: 'get',
    params: {
      type,
      search: searchText,
      isParent,
      skip,
      take,
    },
    paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
  }),

  addUniqueAiObjectRequest: (data) => ({
    url: `api/ai/uniqueObjects`,
    method: 'post',
    data,
  }),
  addUniqueAiObjectConnectionRequest: (data) => ({
    url: `api/ai/uniqueObjects/connection`,
    method: 'post',
    data,
  }),
  getAiCounterInfoRequest: (data) => ({
    url: `api/ai/getAiCounterInfo`,
    method: 'post',
    data,
  }),
  checkConditionsRequest: (data) => ({
    url: `api/formanswers/conditions/check`,
    method: 'post',
    data,
  }),
  getAiObjectsCountRequest: (data) => ({
    url: `api/ai/getAiObjectsCount`,
    method: 'post',
    data,
  }),
  addAiObjectRequest: (data) => ({
    url: `api/ai/objects`,
    method: 'post',
    data,
  }),
  restoreAiObjectRequest: (data) => ({
    url: `api/ai/objects/restore`,
    method: 'post',
    data,
  }),
  deleteAiObjectRequest: (aiObjectId) => ({
    url: `api/ai/tenantObjects/${aiObjectId}`,
    method: 'delete',
  }),
  updateAiObjectRequest: (data) => ({
    url: `api/ai/objects`,
    method: 'put',
    data,
  }),
  getUniqueAiObjectRequest: _getUniqueAiObject,
  getUniqueAiObjectForEditRequest: _getUniqueAiObject,
  updateUniqueAiObjectRequest: (data) => ({
    url: `api/ai/uniqueObjects`,
    method: 'put',
    data,
  }),
  detachStructureModelAiObjectsRequest: (data) => ({
    url: `api/ai/detachStructureModelObjects`,
    method: 'post',
    data,
  }),
  getAiCounterInfoByFormRecordRequest: (data) => ({
    url: `api/ai/getAiCounterInfoByFormRecord`,
    method: 'post',
    data,
  }),
  regenerateStructureModelRequest: (data) => ({
    url: `api/ai/structureModel/regenerate`,
    method: 'post',
    data,
  }),

  getListOfDependentModelsRequest: ({ classId }) => ({
    url: `api/ai/objects/${classId}/dependentModels`,
    method: 'get',
  }),
  createModelToClassDependencyRequest: ({ classId, modelId }) => ({
    url: `api/ai/objects/${classId}/dependentModels/${modelId}`,
    method: 'post',
  }),
  deleteModelToClassDependencyRequest: ({ classId, modelId }) => ({
    url: `api/ai/objects/${classId}/dependentModels/${modelId}`,
    method: 'delete',
  }),
}

export default aiRequests
