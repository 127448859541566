import { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'

import { LinkOutlined, LinkOffOutlined, Translate, OpenWith, RotateLeft, FormatShapes } from '@mui/icons-material'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Box,
} from '@mui/material'

import { AnnotationType } from '@tabeeb/enums'
import { FormikTextField } from '@tabeeb/modules/shared/forms'
import { withModernTheme } from '@tabeeb/modules/shared/uikit'
import { updateContentAnnotation } from '@tabeeb/modules/annotations/actions'

import { getScale, getUnit } from '@tabeeb/modules/pointCloud/selectors'
import { Units } from '@tabeeb/modules/pointCloud/constants'
import { FEET_IN_METER } from '@tabeeb/modules/pointCloud/utils/measurements'
import { get } from 'lodash'
import { requiredFieldWithName } from '@tabeeb/modules/shared/utils/validationErrorMessages'

const toPrecision = (value, precision) => {
  return parseFloat(value.toFixed(precision), 10)
}

const hasAzimuth = (type) => {
  return type === AnnotationType.Model
}

const hasTilt = (type) => {
  return type === AnnotationType.Model
}

const hasAnchor = (type) => {
  return type === AnnotationType.Model || type === AnnotationType.Box || type === AnnotationType.Cylinder
}

const hasRotation = (type) => {
  return type === AnnotationType.Box || type === AnnotationType.Cylinder
}

const hasScale = (type) => {
  return type === AnnotationType.Box || type === AnnotationType.Cylinder
}

const schema = Yup.object().shape({
  azimuth: Yup.number()
    .nullable()
    .when('type', {
      is: (type) => hasAzimuth(type),
      then: Yup.number().min(0, 'Min 0°').max(360, 'Max 360°').required('Required'),
    }),
  tilt: Yup.number()
    .nullable()
    .when('type', {
      is: (type) => hasTilt(type),
      then: Yup.number().min(0, 'Min 0°').max(180, 'Max 180°').required('Required'),
    }),
  Name: Yup.string().nullable(),
  Anchor: Yup.object()
    .shape({
      X: Yup.number().required(requiredFieldWithName('X')),
      Y: Yup.number().required(requiredFieldWithName('Y')),
      Z: Yup.number().required(requiredFieldWithName('Z')),
    })
    .nullable(),
  Rotation: Yup.object()
    .shape({
      X: Yup.number().required(requiredFieldWithName('X')),
      Y: Yup.number().required(requiredFieldWithName('Y')),
      Z: Yup.number().required(requiredFieldWithName('Z')),
    })
    .nullable(),
  Scale: Yup.object()
    .shape({
      X: Yup.number().required(requiredFieldWithName('Width')),
      Y: Yup.number().required(requiredFieldWithName('Height')),
      Z: Yup.number().required(requiredFieldWithName('Depth')),
    })
    .nullable(),
})

const EditAnnotationDialog = ({ open, annotation, onClose }) => {
  const dispatch = useDispatch()

  const worldScale = useSelector(getScale)
  const unit = useSelector(getUnit)
  const unitScale = unit === Units.Meters ? 1 : FEET_IN_METER
  const totalScale = worldScale * unitScale

  const lengthUnitDisplayName = unit === Units.Meters ? 'm' : 'ft'

  const rotationUnitDisplayName = true ? 'rad' : 'deg°'

  const [dimensionsLocked, setDimensionsLocked] = useState(false)

  const onDimensionsChange = (fieldName, setFieldValue, formValues) => (e) => {
    const newValue = e.target.value
    const oldValue = get(formValues, fieldName)
    const scale = newValue / oldValue

    if (dimensionsLocked) {
      setFieldValue('Scale.X', toPrecision(formValues.Scale.X * scale, 2))
      setFieldValue('Scale.Y', toPrecision(formValues.Scale.Y * scale, 2))
      setFieldValue('Scale.Z', toPrecision(formValues.Scale.Z * scale, 2))
    }

    setFieldValue(fieldName, newValue)
  }

  const onSubmit = ({ azimuth, tilt, Name, Anchor, Rotation, Scale }) => {
    dispatch(
      updateContentAnnotation({
        annotation: {
          ...annotation,
          Url: annotation.Url,
          Azimuth: azimuth,
          Tilt: tilt,
          Name,
          Anchor: {
            X: Anchor.X / totalScale,
            Y: Anchor.Y / totalScale,
            Z: Anchor.Z / totalScale,
          },
          Rotation,
          Scale: {
            X: Scale.X / totalScale,
            Y: Scale.Y / totalScale,
            Z: Scale.Z / totalScale,
          },
        },
      })
    )

    onClose()
  }

  const initial = {
    azimuth: annotation?.Azimuth,
    tilt: annotation?.Tilt,
    Name: annotation?.Name,
    type: annotation?.Type,
    Anchor: {
      X: toPrecision((annotation?.Anchor?.X ?? 0) * totalScale, 2),
      Y: toPrecision((annotation?.Anchor?.Y ?? 0) * totalScale, 2),
      Z: toPrecision((annotation?.Anchor?.Z ?? 0) * totalScale, 2),
    },
    Rotation: {
      X: toPrecision(annotation?.Rotation?.X ?? 0, 2),
      Y: toPrecision(annotation?.Rotation?.Y ?? 0, 2),
      Z: toPrecision(annotation?.Rotation?.Z ?? 0, 2),
    },
    Scale: {
      X: toPrecision((annotation?.Scale?.X ?? 0) * totalScale, 2),
      Y: toPrecision((annotation?.Scale?.Y ?? 0) * totalScale, 2),
      Z: toPrecision((annotation?.Scale?.Z ?? 0) * totalScale, 2),
    },
  }

  const positionInputProps = { step: 0.1 }
  const rotationInputProps = { step: 0.1 }
  const sizeInputProps = { step: 0.1 }

  return (
    <Dialog open={open} fullWidth maxWidth='sm' onClose={onClose}>
      <Formik initialValues={initial} enableReinitialize validationSchema={schema} onSubmit={onSubmit}>
        {({ dirty, values, isValid, setFieldValue }) => (
          <Form id='edit-annotation-form' autoComplete='off'>
            <DialogTitle>Edit annotation</DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <Field size='small' name='Name' label='Name' component={FormikTextField} />
                </Grid>

                {hasAnchor(annotation?.Type) && (
                  <>
                    <Grid xs={12} item mt={1} pb={1}>
                      <Box display='flex' alignItems='center'>
                        <OpenWith fontSize='small' />
                        <Typography ml={1} variant='body1' fontWeight={600}>
                          Position
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={4} item>
                      <Field
                        size='small'
                        name='Anchor.X'
                        label={`X, ${lengthUnitDisplayName}`}
                        type='number'
                        component={FormikTextField}
                        inputProps={positionInputProps}
                      />
                    </Grid>
                    <Grid xs={4} item>
                      <Field
                        size='small'
                        name='Anchor.Y'
                        label={`Y, ${lengthUnitDisplayName}`}
                        type='number'
                        component={FormikTextField}
                        inputProps={positionInputProps}
                      />
                    </Grid>
                    <Grid xs={4} item>
                      <Field
                        size='small'
                        name='Anchor.Z'
                        label={`Z, ${lengthUnitDisplayName}`}
                        type='number'
                        component={FormikTextField}
                        inputProps={positionInputProps}
                      />
                    </Grid>
                  </>
                )}

                {hasAzimuth(annotation?.Type) && (
                  <Grid xs={12} item>
                    <Field
                      size='small'
                      name='azimuth'
                      label='Azimuth'
                      type='number'
                      inputProps={{ min: 0, max: 360, step: 1 }}
                      component={FormikTextField}
                    />
                  </Grid>
                )}

                {hasTilt(annotation?.Type) && (
                  <Grid xs={12} item>
                    <Field
                      size='small'
                      name='tilt'
                      label='Tilt'
                      type='number'
                      inputProps={{ min: 0, max: 180, step: 1 }}
                      component={FormikTextField}
                    />
                  </Grid>
                )}

                {hasRotation(annotation?.Type) && (
                  <>
                    <Grid xs={12} item mt={1} pb={1}>
                      <Box display='flex' alignItems='center'>
                        <RotateLeft fontSize='small' />
                        <Typography ml={1} variant='body1' fontWeight={600}>
                          Rotation
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={4} item>
                      <Field
                        size='small'
                        name='Rotation.X'
                        label={`X, ${rotationUnitDisplayName}`}
                        type='number'
                        component={FormikTextField}
                        inputProps={rotationInputProps}
                      />
                    </Grid>
                    <Grid xs={4} item>
                      <Field
                        size='small'
                        name='Rotation.Y'
                        label={`Y, ${rotationUnitDisplayName}`}
                        type='number'
                        component={FormikTextField}
                        inputProps={rotationInputProps}
                      />
                    </Grid>
                    <Grid xs={4} item>
                      <Field
                        size='small'
                        name='Rotation.Z'
                        label={`Z, ${rotationUnitDisplayName}`}
                        type='number'
                        component={FormikTextField}
                        inputProps={rotationInputProps}
                      />
                    </Grid>
                  </>
                )}

                {hasScale(annotation?.Type) && (
                  <>
                    <Grid xs={12} item mt={1} pb={1}>
                      <Box display='flex' alignItems='center'>
                        <FormatShapes fontSize='small' />
                        <Typography ml={1} variant='body1' fontWeight={600}>
                          Dimensions
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={12} alignItems='center' direction='row' flexWrap='nowrap' container item>
                      <Field
                        size='small'
                        name='Scale.X'
                        label={`Width, ${lengthUnitDisplayName}`}
                        type='number'
                        component={FormikTextField}
                        inputProps={sizeInputProps}
                        onChange={onDimensionsChange('Scale.X', setFieldValue, values)}
                      />
                      <Tooltip title='Lock dimensions' placement='top'>
                        <IconButton size='small' onClick={() => setDimensionsLocked(!dimensionsLocked)}>
                          {dimensionsLocked ? <LinkOutlined /> : <LinkOffOutlined />}
                        </IconButton>
                      </Tooltip>
                      <Field
                        size='small'
                        name='Scale.Y'
                        label={`Height, ${lengthUnitDisplayName}`}
                        type='number'
                        component={FormikTextField}
                        inputProps={sizeInputProps}
                        onChange={onDimensionsChange('Scale.Y', setFieldValue, values)}
                      />
                      <Tooltip title='Lock dimensions' placement='top'>
                        <IconButton size='small' onClick={() => setDimensionsLocked(!dimensionsLocked)}>
                          {dimensionsLocked ? <LinkOutlined /> : <LinkOffOutlined />}
                        </IconButton>
                      </Tooltip>
                      <Field
                        size='small'
                        name='Scale.Z'
                        label={`Depth, ${lengthUnitDisplayName}`}
                        type='number'
                        component={FormikTextField}
                        inputProps={sizeInputProps}
                        onChange={onDimensionsChange('Scale.Z', setFieldValue, values)}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                disabled={!dirty || !isValid}
                form='edit-annotation-form'
                type='submit'
                color='primary'
                variant='contained'
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

EditAnnotationDialog.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Anchor: PropTypes.shape({
      X: PropTypes.number.isRequired,
      Y: PropTypes.number.isRequired,
      Z: PropTypes.number.isRequired,
    }),
    Azimuth: PropTypes.number.isRequired,
    Name: PropTypes.string,
    Rotation: PropTypes.shape({
      X: PropTypes.number.isRequired,
      Y: PropTypes.number.isRequired,
      Z: PropTypes.number.isRequired,
    }),
    Scale: PropTypes.shape({
      X: PropTypes.number.isRequired,
      Y: PropTypes.number.isRequired,
      Z: PropTypes.number.isRequired,
    }),
    Tilt: PropTypes.number.isRequired,
    Type: PropTypes.oneOf(Object.values(AnnotationType)).isRequired,
    Url: PropTypes.string.isRequired,
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(withModernTheme(EditAnnotationDialog))
