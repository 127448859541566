import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { maxLength, noWhitespaces, requiredField } from '@tabeeb/modules/shared/utils/validationErrorMessages'
import * as aiActions from '../../actions'
import { getSelectedUniqueAIObject, getSelectedUniqueAIObjectsAIObject } from '../../selectors'

import TextFieldComponent from '../TextFieldComponent'
import UniqueAIObjectPropertiesField from '../UniqueAIObjectPropertiesField'
import { validAIObjectPropertySchema } from '../../services'

const validationSchemaSchema = Yup.object().shape({
  name: Yup.string().strict().trim(noWhitespaces).max(500, maxLength(500)).required(requiredField),
  properties: Yup.array().of(
    Yup.object()
      .shape({
        value: validAIObjectPropertySchema,
      })
      .required()
  ),
})

const EditUniqueAIObjectDialog = () => {
  const dispatch = useDispatch()
  const selectedUniqueAIObject = useSelector(getSelectedUniqueAIObject)
  const aiObject = useSelector(getSelectedUniqueAIObjectsAIObject)

  const handleSubmit = (values, actions) => {
    const model = {
      Id: selectedUniqueAIObject.Id,
      Name: values.name,
      Properties: values.properties
        .filter((property) => property.value !== null && property.value !== undefined && property.value !== '')
        .map((property) => ({
          Id: property.id,
          Type: property.type,
          AIObjectPropertyId: property.aiObjectPropertyId,
          Value: property.value,
          Ordinal: property.ordinal,
        })),
    }

    dispatch(aiActions.updateUniqueAiObjectRequest(model))
    dispatch(aiActions.setUniqueAiObjectForEdit(null))
  }

  const handleClose = (values, actions) => {
    dispatch(aiActions.setUniqueAiObjectForEdit(null))
  }

  const selectedUniqueAIObjectPropertiesMap = useMemo(() => {
    if (!selectedUniqueAIObject) {
      return {}
    }

    return Object.fromEntries(
      selectedUniqueAIObject.Properties.map((property) => [property.AIObjectPropertyId, property])
    )
  }, [selectedUniqueAIObject])

  const initialValues = useMemo(
    () => ({
      name: selectedUniqueAIObject?.Name || '',
      properties: aiObject?.Properties
        ? aiObject.Properties.map((aiObjectProperty) => ({
            id: selectedUniqueAIObjectPropertiesMap[aiObjectProperty.Id]?.Id,
            aiObjectPropertyId: aiObjectProperty.Id,
            name: aiObjectProperty.Name,
            type: aiObjectProperty.Type,
            isDisabled: aiObjectProperty.IsDisabled,
            value: selectedUniqueAIObjectPropertiesMap[aiObjectProperty.Id]?.Value || null,
            ordinal: aiObjectProperty.Ordinal,
          }))
        : [],
    }),
    [aiObject.Properties, selectedUniqueAIObject?.Name, selectedUniqueAIObjectPropertiesMap]
  )

  return (
    <Dialog open={Boolean(selectedUniqueAIObject)} fullWidth maxWidth='md' onClose={handleClose}>
      <DialogTitle>Edit properties</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchemaSchema}
      >
        {({ isSubmitting, dirty, values, errors }) => (
          <Form>
            <DialogContent>
              <Field
                disabled
                name='name'
                placeholder='Name'
                label='Name'
                variant='outlined'
                sx={{ marginBottom: 1 }}
                component={TextFieldComponent}
              />
              <Field name='properties' component={UniqueAIObjectPropertiesField} />
            </DialogContent>
            <DialogActions>
              <Button type='submit' disabled={!dirty || isSubmitting}>
                Save
              </Button>
              <Button onClick={handleClose} color='primary'>
                Cancel
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default EditUniqueAIObjectDialog
