import { all, takeLatest, takeEvery, put } from 'redux-saga/effects'

import * as aiActions from '../actions'
import * as notificationActions from '../../notification/actions'
import { signalrEvents, signalrConstants } from '../../signalr'
import { getAiAnnotationPropertiesText } from '../../annotations/services/annotationsService'
import { galleryActions } from '../../gallery'

function* onUpdateUniqueAiObjectSuccess(action) {
  yield put(notificationActions.onAddInfoNotification({ message: 'Properties successfully updated' }))
  const uniqueAiObject = action.response.data

  const propertiesText = getAiAnnotationPropertiesText(uniqueAiObject.Properties)
  yield put(
    galleryActions.updateAiAnnotationsText({
      uniqueAiObjectId: uniqueAiObject.Id,
      uniqueAiObjectName: uniqueAiObject.Name,
      propertiesText,
    })
  )
}

function* onUpdateUniqueAiObjectFailed(action) {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to update the object.' }))
}

function* onUniqueAIObjectUpdated(action) {
  const [uniqueAiObject] = action.payload

  const propertiesText = getAiAnnotationPropertiesText(uniqueAiObject.Properties)

  yield put(
    galleryActions.updateAiAnnotationsText({
      uniqueAiObjectId: uniqueAiObject.Id,
      uniqueAiObjectName: uniqueAiObject.Name,
      propertiesText,
    })
  )
}

function* updateUniqueAIObject() {
  yield all([
    takeLatest(aiActions.updateUniqueAiObjectSuccess, onUpdateUniqueAiObjectSuccess),
    takeLatest(aiActions.updateUniqueAiObjectFailed, onUpdateUniqueAiObjectFailed),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onUniqueAIObjectUpdated, onUniqueAIObjectUpdated),
  ])
}

export default updateUniqueAIObject
