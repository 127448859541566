import { memo } from 'react'
import PropTypes from 'prop-types'

import { AnnotationType } from '@tabeeb/enums'

import { IconButton, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Tooltip } from '@mui/material'
import { EditOutlined, DeleteOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'

import AnnotationIcon from './AnnotationIcon'

const getDefaultAnnotationName = (type) => {
  if (type === AnnotationType.Area) {
    return 'Area'
  }

  if (type === AnnotationType.AIHotspot) {
    return 'Hotspot'
  }

  if (type === AnnotationType.Box) {
    return 'Box'
  }

  if (type === AnnotationType.Cylinder) {
    return 'Cylinder'
  }

  if (type === AnnotationType.Line) {
    return 'Line'
  }

  if (type === AnnotationType.Distance) {
    return 'Distance'
  }

  if (type === AnnotationType.Height) {
    return 'Height'
  }

  if (type === AnnotationType.Model) {
    return 'Model'
  }

  return 'Annotation'
}

const Annotation = ({ annotation, onDelete, onEdit, onToggleVisibility }) => {
  return (
    <ListItem button disableGutters>
      <ListItemIcon>
        <AnnotationIcon type={annotation.Type} />
      </ListItemIcon>
      <ListItemText primary={annotation.Name || getDefaultAnnotationName(annotation.Type)} />
      <ListItemSecondaryAction>
        <Tooltip title={annotation.Hidden ? 'Show' : 'Hide'}>
          <IconButton size='small' onClick={() => onToggleVisibility(annotation)}>
            {annotation.Hidden ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
          </IconButton>
        </Tooltip>
        <Tooltip title='Edit'>
          <IconButton size='small' onClick={() => onEdit(annotation)}>
            <EditOutlined />
          </IconButton>
        </Tooltip>
        <Tooltip title='Delete'>
          <IconButton size='small' onClick={() => onDelete(annotation.Id)}>
            <DeleteOutlined />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

Annotation.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string,
    Type: PropTypes.oneOf(Object.values(AnnotationType)).isRequired,
    Hidden: PropTypes.bool,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onToggleVisibility: PropTypes.func.isRequired,
}

export default memo(Annotation)
