import { memo, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
  AddOutlined,
  EditOutlined,
  GroupAddOutlined,
  GroupRemoveOutlined,
  KeyboardArrowLeft,
} from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import { UserInfo } from '@tabeeb/uikit'
import { UserRole } from '@tabeeb/enums'
import { callApiAsync } from '@tabeeb/state/sagas/watchRequest'
import { useDebouncedValue } from '@tabeeb/shared/utils/hooks'
import { sessionTitleFormatter, trainingMaterialTitleFormatter } from '@tabeeb/shared/utils/text'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'
import { getUsersListForCurrentUser } from '@tabeeb/modules/../users/selectors'

import { useTrainingMaterials } from '../../hooks'
import { createContentTrainingMaterial } from '../../actions'

import NoTrainingMaterialsFoundPlaceholder from '../NoTrainingMaterialsFoundPlaceholder'
import TrainingMaterialListItem from '../TrainingMaterialListItem'
import TrainingMaterialListItemSkeleton from '../TrainingMaterialListItemSkeleton'
import Repeater from '../Repeater'

import { View } from './styles'

const TrainingMaterialsAttachDialog = ({ open, onClose, contentId, onUpdated }) => {
  const dispatch = useDispatch()

  const onError = useCallback(() => {
    dispatch(
      onAddErrorNotification({ message: trainingMaterialTitleFormatter.format('Failed to load training materials') })
    )
    onClose()
  }, [dispatch, onClose])

  const [isUsersConfigurationOpen, setIsUsersConfigurationOpen] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [attachInProgress, setAttachInProgress] = useState(false)
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebouncedValue(searchText, 500)

  const currentUserId = useSelector(getCurrentUserId)
  const users = useSelector(getUsersListForCurrentUser)
  const visibleUsers = useMemo(
    () =>
      _.orderBy(
        users.filter((u) => !u.isDeleted && u.role !== UserRole.AIUser && u.role !== UserRole.Reviewer),
        (user) => user.id !== currentUserId
      ),
    [currentUserId, users]
  )

  const { loading, loaded, trainingMaterials, onReload } = useTrainingMaterials({
    enabled: open,
    contentId,
    attach: true,
    search: debouncedSearchText,
    onError,
  })

  const onSelectUser = useCallback((user) => {
    setSelectedUsers((prevUsers) => {
      if (prevUsers.includes(user)) {
        return prevUsers.filter((u) => u !== user)
      }

      return [...prevUsers, user]
    })
  }, [])

  const onSelectAll = useCallback(() => {
    setSelectedUsers(visibleUsers)
  }, [visibleUsers])

  const onClearAll = useCallback(() => {
    setSelectedUsers([])
  }, [])

  const onReset = useCallback(() => {
    setIsUsersConfigurationOpen(false)
    setSelectedUsers(visibleUsers)
  }, [visibleUsers])

  const onAttach = async (trainingMaterial) => {
    try {
      setAttachInProgress(true)
      await callApiAsync(
        createContentTrainingMaterial.request({
          contentId,
          trainingMaterialId: trainingMaterial.Id,
          userIds: selectedUsers.map((u) => u.id),
        })
      )
      onReload()
      onUpdated()
    } catch (e) {
      dispatch(onAddErrorNotification({ message: 'Failed to attach material' }))
    } finally {
      setAttachInProgress(false)
    }
  }

  const noUsersSelected = selectedUsers.length === 0
  const allUsersSelected = visibleUsers.length === selectedUsers.length

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='sm'
      onClose={onClose}
      TransitionProps={{ onEnter: onReset, onExited: onReset }}
    >
      <DialogTitle display='flex' alignItems='center'>
        {trainingMaterialTitleFormatter.format('Attach training material')}
      </DialogTitle>
      <DialogContent sx={{ padding: 0, overflow: 'hidden', position: 'relative', height: 450 }}>
        <View data-active-view={!isUsersConfigurationOpen} data-main-view>
          <Alert
            sx={{ marginInline: 2, mb: 1, alignItems: 'center' }}
            color={noUsersSelected ? 'warning' : 'info'}
            severity={noUsersSelected ? 'warning' : 'info'}
            action={
              <Button
                disabled={loading || attachInProgress}
                startIcon={<EditOutlined />}
                size='small'
                color='primary'
                variant='outlined'
                onClick={() => setIsUsersConfigurationOpen(true)}
              >
                Edit
              </Button>
            }
          >
            {selectedUsers.length === 0
              ? 'No users selected'
              : trainingMaterialTitleFormatter.format(
                  `Training material will be assigned to ${
                    allUsersSelected
                      ? sessionTitleFormatter.format('all session users')
                      : `${selectedUsers.length} selected user(s)`
                  }`
                )}
          </Alert>

          <Box display='flex' paddingInline={2} paddingBottom={1}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              placeholder='Search...'
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Box>

          <List sx={{ overflow: 'auto', paddingInline: 2, paddingLeft: 3.5 }}>
            {!loaded && trainingMaterials.length === 0 && (
              <Repeater count={6}>
                <TrainingMaterialListItemSkeleton />
              </Repeater>
            )}

            {trainingMaterials.map((trainingMaterial) => (
              <TrainingMaterialListItem key={trainingMaterial.Id} disabled={loading} item={trainingMaterial}>
                <Button
                  disabled={loading || attachInProgress}
                  startIcon={<AddOutlined />}
                  variant='contained'
                  color='primary'
                  onClick={() => onAttach(trainingMaterial)}
                >
                  Attach
                </Button>
              </TrainingMaterialListItem>
            ))}
          </List>

          {loaded && trainingMaterials.length === 0 && <NoTrainingMaterialsFoundPlaceholder />}
        </View>

        <View data-active-view={isUsersConfigurationOpen} data-configuration-view>
          <Box paddingInline={2} mb={2} mt={1} display='flex' alignItems='center'>
            <Box display='flex' alignItems='center' flexGrow={1}>
              <Tooltip title='Go back'>
                <IconButton size='small' onClick={() => setIsUsersConfigurationOpen(false)}>
                  <KeyboardArrowLeft />
                </IconButton>
              </Tooltip>
              <Typography ml={1} variant='body1' fontWeight={600}>
                {selectedUsers.length} user(s) selected
              </Typography>
            </Box>
            <Button
              startIcon={allUsersSelected ? <GroupRemoveOutlined /> : <GroupAddOutlined />}
              color={allUsersSelected ? 'error' : 'primary'}
              size='small'
              variant='outlined'
              onClick={allUsersSelected ? onClearAll : onSelectAll}
            >
              {allUsersSelected ? 'Clear all' : 'Select all'}
            </Button>
            <Button
              sx={{ ml: 1 }}
              startIcon={<KeyboardArrowLeft />}
              color='primary'
              size='small'
              variant='outlined'
              onClick={() => setIsUsersConfigurationOpen(false)}
            >
              Go back
            </Button>
          </Box>
          <Grid container spacing={2} rowSpacing={1} paddingInline={2} overflow='auto'>
            {visibleUsers.map((user) => {
              const selected = selectedUsers.some((selectedUser) => selectedUser.id === user.id)

              return (
                <Grid key={user.id} item xs={6}>
                  <ListItemButton selected={selected} onClick={() => onSelectUser(user)}>
                    <ListItemIcon>
                      <Checkbox edge='start' checked={selected} />
                    </ListItemIcon>
                    <UserInfo
                      item={{
                        Id: user.id,
                        Name: user.displayName,
                        Email: user.email,
                        IsAccountDeleted: user.isAccountDeleted,
                        AvatarUrl: user.avatarUrl,
                      }}
                    />
                  </ListItemButton>
                </Grid>
              )
            })}
          </Grid>
        </View>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

TrainingMaterialsAttachDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
  contentId: PropTypes.number,
}

export default memo(TrainingMaterialsAttachDialog)
