import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { TextField, FormControl, Typography, withStyles } from '@material-ui/core'

import { contentStateSelectors } from '@tabeeb/shared/content'
import { aiSelectors, aiActions } from '@tabeeb/modules/artificialIntelligence'

import { Autocomplete } from '@mui/material'
import { getScale, getUnit } from '@tabeeb/modules/pointCloud/selectors'
import { FEET_IN_METER } from '@tabeeb/modules/pointCloud/utils/measurements'
import { Units } from '@tabeeb/modules/pointCloud/constants'
import { AnnotationType } from '@tabeeb/enums'
import { getParentOfHotspotDialogAnnotation } from '../../selectors'
import styles from './styles'

const maxUniqueAiObjectNameLength = 500

const AIAnnotationEditor = ({ classes, onChange }) => {
  const dispatch = useDispatch()

  const [newUniqueAiObjectName, setNewUniqueAiObjectName] = useState('')
  const [selectedUniqueAiObject, setSelectedUniqueAiObject] = useState(null)

  const parent = useSelector(getParentOfHotspotDialogAnnotation)
  const worldScale = useSelector(getScale)
  const unit = useSelector(getUnit)
  const unitScale = unit === Units.Meters ? 1 : FEET_IN_METER
  const totalScale = worldScale * unitScale
  const lengthUnitDisplayName = unit === Units.Meters ? 'm' : 'ft'

  const selectedAiObject = useSelector(aiSelectors.getSelectedAiObject)
  const contentId = useSelector(contentStateSelectors.getContentId)
  const aiObjects = useSelector(aiSelectors.getAIObjects)
  const uniqueAiObjects = useSelector((state) =>
    aiSelectors.getUniqueAIObjectsByAIObjectId(state, { Id: selectedAiObject.Id })
  )

  const handleChangeAIObject = useCallback(
    (e, value, reason) => {
      if (!value) {
        return
      }

      const newAIObjectId = value.Id
      const selectedAIObject = aiObjects.find((object) => object.Id === newAIObjectId)

      setSelectedUniqueAiObject(null)
      dispatch(aiActions.setSelectedAiObject(selectedAIObject))
      dispatch(aiActions.getUniqueAiObjects.request({ aiObjectId: newAIObjectId, contentId }))
    },
    [aiObjects, contentId, dispatch]
  )

  const handleChangeUniqueAIObject = useCallback((e, value) => {
    setSelectedUniqueAiObject(value)
  }, [])

  const handleChangeText = useCallback((e) => {
    setNewUniqueAiObjectName(e.target.value)
  }, [])

  useEffect(() => {
    const isUniqueAiObjectAlreadyExists = uniqueAiObjects.some((item) => item.Name === newUniqueAiObjectName)
    const isMaxLengthExceeded = newUniqueAiObjectName.length > maxUniqueAiObjectNameLength
    if (isUniqueAiObjectAlreadyExists || isMaxLengthExceeded) {
      onChange(undefined)
      return
    }

    onChange({
      newUniqueAiObjectName,
      selectedAiObject,
      selectedUniqueAiObject: selectedUniqueAiObject || null,
    })
  }, [selectedAiObject, selectedUniqueAiObject, newUniqueAiObjectName])

  const isUniqueAiObjectAlreadyExists = uniqueAiObjects.some((item) => item.Name === newUniqueAiObjectName)
  const isMaxLengthExceeded = newUniqueAiObjectName.length > maxUniqueAiObjectNameLength

  const customFilterOptions = (options, state) => {
    if (!state.inputValue) {
      return options
    }

    const pattern = state.inputValue.replace(/ /g, '.*')
    const regex = new RegExp(pattern, 'i')

    return options.filter((option) => regex.test(option.Name))
  }

  const toPrecision = (value, precision) => {
    return parseFloat(value.toFixed(precision), 10)
  }

  return (
    <>
      {parent && (parent.Type === AnnotationType.Cylinder || parent.Type === AnnotationType.Box) && (
        <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '8px' }}>
          <span>
            <strong>Width, {lengthUnitDisplayName}:</strong> {toPrecision((parent?.Scale?.X ?? 0) * totalScale, 2)}
          </span>
          <span>
            <strong>Height, {lengthUnitDisplayName}:</strong> {toPrecision((parent?.Scale?.Y ?? 0) * totalScale, 2)}
          </span>
          <span>
            <strong>Depth, {lengthUnitDisplayName}:</strong> {toPrecision((parent?.Scale?.Z ?? 0) * totalScale, 2)}
          </span>
        </div>
      )}
      <FormControl size='small' variant='outlined' className={classes.formControl} fullWidth>
        <Autocomplete
          fullWidth
          value={selectedAiObject}
          onChange={handleChangeAIObject}
          options={aiObjects}
          filterOptions={customFilterOptions}
          getOptionLabel={(option) => option.Name}
          renderTags={() => []}
          isOptionEqualToValue={(option, value) => value.Id === option.Id}
          renderOption={(props, option) => (
            <Typography {...props} noWrap title={option.Name} key={option.Id}>
              {option.Name}
            </Typography>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              variant='outlined'
              label='Select a CV class'
              placeholder='Search a CV class...'
            />
          )}
        />
      </FormControl>

      <FormControl size='small' variant='outlined' className={classes.formControl} fullWidth>
        <Autocomplete
          disabled={Boolean(newUniqueAiObjectName)}
          fullWidth
          value={selectedUniqueAiObject}
          onChange={handleChangeUniqueAIObject}
          options={uniqueAiObjects}
          getOptionLabel={(option) => option.Name}
          filterOptions={customFilterOptions}
          renderTags={() => []}
          isOptionEqualToValue={(option, value) => value.Id === option.Id}
          renderOption={(props, option) => (
            <Typography {...props} noWrap title={option.Name} key={option.Id}>
              {option.Name}
            </Typography>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label={`Select ${selectedAiObject.Name}`}
              placeholder='Search an object...'
            />
          )}
        />
      </FormControl>

      <Typography variant='body2'>or create new one</Typography>
      <TextField
        fullWidth
        className={classes.textField}
        value={newUniqueAiObjectName}
        size='small'
        variant='outlined'
        onChange={handleChangeText}
        disabled={Boolean(selectedUniqueAiObject)}
        helperText={
          (isUniqueAiObjectAlreadyExists && 'An object with the same name already exists') ||
          (isMaxLengthExceeded && `Max length is ${maxUniqueAiObjectNameLength}`)
        }
        error={isUniqueAiObjectAlreadyExists || isMaxLengthExceeded}
        placeholder='Enter object name here...'
      />
    </>
  )
}

AIAnnotationEditor.propTypes = {
  classes: PropTypes.shape({
    formControl: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    textField: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(AIAnnotationEditor)
