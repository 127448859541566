import { memo, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import { Euler, Vector3 } from 'three'

import { useSelector } from 'react-redux'
import { Edges, useCursor } from '@react-three/drei'

import { useTransformationControls } from '@tabeeb/modules/pointCloud/hooks'
import { getColorOpacity } from '@tabeeb/modules/pointCloud/utils/color'
import { getWorldRotation } from '@tabeeb/modules/pointCloud/selectors'

import { toVector } from '../../../utils/vectors'

import TransformationControls from '../TransformationControls'

const Box = ({ annotation, raycasting, children, hovered, onHoverEnter, onHoverLeave, selected, onSelect }) => {
  const shapeRef = useRef()

  const worldRotation = useSelector(getWorldRotation)

  const rotation = useMemo(() => {
    if (annotation.Rotation) {
      return new Euler(annotation.Rotation.X, annotation.Rotation.Y, annotation.Rotation.Z)
    }

    return new Euler(0, 0, 0)
  }, [annotation.Rotation])

  const scale = useMemo(() => {
    if (annotation.Scale) {
      return new Vector3(annotation.Scale.X, annotation.Scale.Y, annotation.Scale.Z)
    }

    return new Vector3(1, 1, 1)
  }, [annotation.Scale])

  const { onRotate, onScale, onTranslate } = useTransformationControls({ annotation })

  const visible = !annotation.Hidden
  const highlighted = hovered || selected
  const position = toVector(annotation.Anchor).applyEuler(worldRotation)

  useCursor(hovered, 'pointer')

  return (
    <group>
      <group
        ref={shapeRef}
        visible={visible}
        position={position}
        rotation={rotation}
        scale={scale}
        onClick={onSelect}
        onPointerEnter={onHoverEnter}
        onPointerLeave={onHoverLeave}
      >
        <mesh disableRaycasting={!raycasting} position={new Vector3(0.5, 0.5, 0.5)}>
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial
            color={annotation.Color}
            opacity={getColorOpacity(annotation.Color) * (highlighted ? 0.4 : 0.25)}
            transparent
          />
          <Edges color={annotation.Color} />
        </mesh>

        <group position={new Vector3(1, 1, 1)}>{children}</group>
      </group>
      {visible && selected && (
        <TransformationControls
          object={shapeRef.current}
          onRotate={onRotate}
          onScale={onScale}
          onTranslate={onTranslate}
        />
      )}
    </group>
  )
}

Box.defaultProps = {
  raycasting: true,
  hovered: false,
  selected: false,
}

Box.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number,
    Anchor: PropTypes.shape({
      X: PropTypes.number,
      Y: PropTypes.number,
      Z: PropTypes.number,
    }).isRequired,
    Color: PropTypes.string.isRequired,
    Rotation: PropTypes.shape({
      X: PropTypes.number,
      Y: PropTypes.number,
      Z: PropTypes.number,
    }),
    Scale: PropTypes.shape({
      X: PropTypes.number,
      Y: PropTypes.number,
      Z: PropTypes.number,
    }),
    Hidden: PropTypes.bool,
    Width: PropTypes.number.isRequired,
  }).isRequired,
  children: PropTypes.element,
  raycasting: PropTypes.bool,
  hovered: PropTypes.bool,
  onHoverEnter: PropTypes.func,
  onHoverLeave: PropTypes.func,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}

export default memo(Box)
