/* eslint-disable react/no-array-index-key */
import { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { getDistance } from '../../../utils/measurements'
import { toVector } from '../../../utils/vectors'
import { getIntermediate } from '../../../utils'
import { Defaults } from '../../../constants'
import { useAnnotationState } from '../../../hooks'
import { getScale, getUnit, getWorldRotation } from '../../../selectors'

import Label from '../Label'
import Line from '../Line'
import Points from '../Points'

const getLabelPosition = (points, distance, unit, scale) => {
  let distanceTarget = distance / 2

  for (let i = 0; i < points.length - 1; i++) {
    const lineDistance = getDistance([points[i], points[i + 1]], unit, scale)
    if (lineDistance > distanceTarget) {
      return getIntermediate(points[i], points[i + 1], distanceTarget / lineDistance)
    }

    distanceTarget -= lineDistance
  }

  return points[0]
}

const Distance = ({ annotation: sourceAnnotation, hovered, onHoverEnter, onHoverLeave, selected, onSelect }) => {
  const unit = useSelector(getUnit)
  const scale = useSelector(getScale)
  const worldRotation = useSelector(getWorldRotation)
  const { annotation, onAddPoint, onPointDelete, onPointUpdate, onUpdateCommit } = useAnnotationState(sourceAnnotation)

  const points = annotation.Points.map(toVector)
  const visible = !annotation.Hidden

  const distance = getDistance(points, unit, scale)

  return (
    <group
      visible={visible}
      rotation={worldRotation}
      onPointerDown={onSelect}
      onPointerEnter={onHoverEnter}
      onPointerLeave={onHoverLeave}
    >
      <Points
        points={points}
        visible={selected && visible}
        onAdd={onAddPoint}
        onDelete={onPointDelete}
        onUpdate={onPointUpdate}
        onUpdateCommit={onUpdateCommit}
      />

      <Line
        points={points}
        color={annotation.Color}
        visible={visible}
        highlighted={hovered || selected}
        width={annotation.Width}
      />

      <Label
        visible={visible}
        position={getLabelPosition(points, distance, unit)}
        text={`${distance.toFixed(Defaults.CALCULATIONS_PRECISION)} ${unit}`}
      />
    </group>
  )
}

Distance.defaultProps = {
  selected: false,
}

Distance.propTypes = {
  annotation: PropTypes.shape({
    Color: PropTypes.string.isRequired,
    Hidden: PropTypes.bool,
    Points: PropTypes.arrayOf(
      PropTypes.shape({
        X: PropTypes.number.isRequired,
        Y: PropTypes.number.isRequired,
        Z: PropTypes.number.isRequired,
      })
    ).isRequired,
    Width: PropTypes.number.isRequired,
  }).isRequired,
  hovered: PropTypes.bool,
  onHoverEnter: PropTypes.func,
  onHoverLeave: PropTypes.func,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}

export default memo(Distance)
