import { memo } from 'react'
import PropTypes from 'prop-types'

import { AnnotationType } from '@tabeeb/enums'
import { AreaAnnotation, HeightAnnotation, DistanceAnnotation } from '@tabeeb/shared/icons'

import { CircleOutlined, LocalOfferOutlined, ViewInArOutlined } from '@mui/icons-material'

const AnnotationIcon = ({ type }) => {
  if (type === AnnotationType.Area) {
    return <AreaAnnotation />
  }

  if (type === AnnotationType.AIHotspot) {
    return <LocalOfferOutlined />
  }

  if (type === AnnotationType.Box) {
    return <ViewInArOutlined />
  }

  if (type === AnnotationType.Cylinder) {
    return <CircleOutlined />
  }

  if (type === AnnotationType.Distance) {
    return <DistanceAnnotation />
  }

  if (type === AnnotationType.Height) {
    return <HeightAnnotation />
  }

  if (type === AnnotationType.Model) {
    return <ViewInArOutlined />
  }

  return <DistanceAnnotation />
}

AnnotationIcon.propTypes = {
  type: PropTypes.oneOf(Object.values(AnnotationType)).isRequired,
}

export default memo(AnnotationIcon)
