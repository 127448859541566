import { put, takeEvery, select, take, all } from 'redux-saga/effects'

import { galleryActions } from '@tabeeb/modules/gallery'

import { aiActions } from '@tabeeb/modules/artificialIntelligence'
import { AnnotationType } from '@tabeeb/enums'
import { contentStateSelectors } from '@tabeeb/modules/shared/content'
import { annotationsActions, annotationsSelectors } from '..'

function* deleteContentAnnotations(action) {
  const { annotationIds, ...rest } = action.payload

  const annotations = yield select((state) =>
    annotationIds.map((annotationId) => annotationsSelectors.getAnnotationById(state, { Id: annotationId }))
  )

  yield put(galleryActions.deleteAnnotations(annotations))
  yield put(annotationsActions.deleteAnnotationsRequest({ annotationIds, annotations, ...rest }))

  const result = yield take([annotationsActions.deleteAnnotationsFailed, annotationsActions.deleteAnnotationsSuccess])
  if (annotations && result.type === annotationsActions.deleteAnnotationsFailed().type) {
    yield put(galleryActions.addAnnotations(annotations))
  }
}

function* deleteAnnotationsSuccess(action) {
  const { contentId } = yield select(contentStateSelectors.getContentId)
  const { contentForms } = yield select((state) => state.forms)
  const { annotations } = action.payload

  const hasCounters = contentForms?.some((cf) => cf.HasCounters || (cf.Counters && cf.Counters.length > 0))

  if (annotations.some((annotation) => annotation?.Type === AnnotationType.AI)) {
    if (hasCounters) {
      yield put(aiActions.getAiCounterInfoRequest({ contentId }))
      yield put(aiActions.getAiObjectsCountRequest({ contentId }))
    }
  }
}

function* deleteAnnotationSaga() {
  yield all([
    takeEvery(annotationsActions.deleteContentAnnotations, deleteContentAnnotations),
    takeEvery(annotationsActions.deleteAnnotationsSuccess, deleteAnnotationsSuccess),
  ])
}

export default deleteAnnotationSaga
