import { Mesh } from 'three'

export default () => {
  const originalMeshRaycast = Mesh.prototype.raycast

  function raycast(...args) {
    if (this.disableRaycasting) {
      return
    }

    originalMeshRaycast.call(this, ...args)
  }

  Mesh.prototype.raycast = raycast
}
