import { useCallback, memo } from 'react'
import { useDispatch } from 'react-redux'
import { replace } from 'connected-react-router'
import PropTypes from 'prop-types'

import Routes from '@tabeeb/routes'
import { callApiAsync } from '@tabeeb/state/sagas/watchRequest'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

import { updateAnnouncement } from '@tabeeb/modules/articles/actions'
import { useAnnouncement } from '@tabeeb/modules/articles/hooks'

import AnnouncementEditorPage from '../AnnouncementEditorPage'

const AnnouncementEditPage = ({ id }) => {
  const dispatch = useDispatch()

  const onError = useCallback(() => {
    dispatch(onAddErrorNotification({ message: 'Failed to load announcement' }))
    dispatch(replace(Routes.announcements))
  }, [dispatch])

  const onSubmit = useCallback(
    async (updatedAnnouncement) => {
      try {
        await callApiAsync(updateAnnouncement.request(updatedAnnouncement))
        dispatch(onAddSuccessNotification({ message: 'Announcement successfully updated' }))
        dispatch(replace(Routes.announcements))
      } catch {
        dispatch(onAddErrorNotification({ message: 'Failed to update announcement' }))
      }
    },
    [dispatch]
  )

  const { loading, announcement } = useAnnouncement({ id, onError })
  if (loading || !announcement) {
    return <CircularProgressBar />
  }

  return <AnnouncementEditorPage title='Edit announcement' initial={announcement} onSubmit={onSubmit} />
}

AnnouncementEditPage.propTypes = {
  id: PropTypes.number.isRequired,
}

export default memo(AnnouncementEditPage)
