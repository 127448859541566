/* eslint-disable compat/compat */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { FetchStatus } from '@tabeeb/enums'
import { applicationStore } from '@tabeeb/state/createStore'
import { getGalleryList } from '@tabeeb/modules/gallery/selectors'

import { parseImagesMetadataFile } from '../utils/metadata'
import { getOrientationsFileUrl } from '../selectors'

const useModelSourcePages = () => {
  const [status, setStatus] = useState(FetchStatus.Idle)
  const [pages, setPages] = useState([])

  const url = useSelector(getOrientationsFileUrl)

  useEffect(() => {
    const abortController = new AbortController()

    const fetchImages = async (fileUrl) => {
      try {
        setStatus(FetchStatus.Loading)
        const xml = await (await fetch(fileUrl, { signal: abortController.signal })).text()

        const images = parseImagesMetadataFile({ text: xml })

        const tagToPageMap = new Map()
        for (const page of getGalleryList(applicationStore.getState())) {
          const pageTag = page.name.replace(/[\W_]+/g, '')

          tagToPageMap.set(pageTag, page)
        }

        const sourcePages = []

        for (const image of images) {
          if (!tagToPageMap.has(image.tag)) {
            continue
          }

          const page = tagToPageMap.get(image.tag)

          sourcePages.push({
            id: page.id,
            name: page.name,
            image: {
              ...image,
              url: page.thumbnailUrl,
            },
          })
        }

        setStatus(FetchStatus.Loaded)
        setPages(sourcePages)
      } catch {
        setStatus(FetchStatus.Failed)
      }
    }

    if (url) {
      fetchImages(url)
    }

    return () => {
      abortController.abort()
      setStatus(FetchStatus.Idle)
      setPages([])
    }
  }, [url])

  return {
    loading: status === FetchStatus.Loading,
    loaded: status === FetchStatus.Loaded,
    pages,
  }
}

export default useModelSourcePages
