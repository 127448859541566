import { memo, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, Tooltip } from '@mui/material'

import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'
import { getUsersListForCurrentUser } from '@tabeeb/modules/../users/selectors'

import { UserRole } from '@tabeeb/enums'
import { UserInfoSkeleton } from '@tabeeb/uikit'
import { trainingMaterialTitleFormatter } from '@tabeeb/shared/utils/text'
import { callApiAsync } from '@tabeeb/state/sagas/watchRequest'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'

import { assignContentTrainingMaterialToUsers } from '../../actions'
import { useContentTrainingMaterialUsers } from '../../hooks'

import ArticleReadStatusChip from '../ArticleReadStatusChip'
import CollapsibleSection from '../CollapsibleSection'
import ContentTrainingMaterialUserListItem from '../ContentTrainingMaterialUserListItem'
import Repeater from '../Repeater'

const ContentTrainingMaterialUsersDialog = ({ open, id, onClose, onUpdated }) => {
  const dispatch = useDispatch()

  const currentUserId = useSelector(getCurrentUserId)
  const contentUsers = useSelector(getUsersListForCurrentUser)

  const visibleUsers = useMemo(
    () =>
      contentUsers.filter(
        (user) => !user.isDeleted && user.role !== UserRole.AIUser && user.role !== UserRole.Reviewer
      ),
    [contentUsers]
  )

  const { loading, users, onReload } = useContentTrainingMaterialUsers({
    enabled: !!id && open,
    id,
    onError: onClose,
  })

  const onAssign = async (user) => {
    try {
      await callApiAsync(
        assignContentTrainingMaterialToUsers.request({
          id,
          userIds: [user.id],
        })
      )

      onReload()

      if (user.id === currentUserId) {
        onUpdated()
      }
    } catch (e) {
      dispatch(onAddErrorNotification({ message: 'Failed to assign' }))
    }
  }

  const assignedUsers = useMemo(() => {
    return visibleUsers.filter((user) => users.some((s) => s.UserId === user.id))
  }, [visibleUsers, users])

  const unassignedUsers = useMemo(() => {
    return visibleUsers.filter((user) => !assignedUsers.some((u) => u.id === user.id))
  }, [assignedUsers, visibleUsers])

  return (
    <Dialog open={open} fullWidth maxWidth='sm' onClose={onClose}>
      <DialogTitle display='flex' alignItems='center'>
        {trainingMaterialTitleFormatter.format('Manage training material users')}
      </DialogTitle>
      <DialogContent>
        {loading && (
          <Repeater count={visibleUsers.length}>
            <ListItem disableGutters>
              <UserInfoSkeleton />
            </ListItem>
          </Repeater>
        )}

        {!loading && assignedUsers.length > 0 && (
          <CollapsibleSection title='Assigned users'>
            {assignedUsers.map((user) => {
              const status = users.find((s) => s.UserId === user.id)

              return (
                <ContentTrainingMaterialUserListItem key={user.id} user={user}>
                  <ArticleReadStatusChip read={status.Read} />
                </ContentTrainingMaterialUserListItem>
              )
            })}
          </CollapsibleSection>
        )}

        {!loading && unassignedUsers.length > 0 && (
          <CollapsibleSection title='Unassigned users'>
            {unassignedUsers.map((user) => {
              return (
                <ContentTrainingMaterialUserListItem key={user.id} user={user}>
                  <Tooltip title={trainingMaterialTitleFormatter.format('Assign user to training material')}>
                    <Button color='primary' variant='contained' onClick={() => onAssign(user)}>
                      Assign
                    </Button>
                  </Tooltip>
                </ContentTrainingMaterialUserListItem>
              )
            })}
          </CollapsibleSection>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

ContentTrainingMaterialUsersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
}

export default memo(ContentTrainingMaterialUsersDialog)
