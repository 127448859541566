import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as rawActions from '../actions'
import * as customVideoActions from '../../customVideo/actions'
import * as spatialModelActions from '../../spatialModel/actions'
import * as threedeeModelActions from '../../3dmodel/actions'
import * as bentleyModelActions from '../../bentleyModel/actions'
import * as bentleyAnalyticsReportActions from '../../bentleyAnalyticsReport/actions'
import * as sessionSharingActions from '../../shareSessionPagesList/actions'
import * as structureModelActions from '../../structureModel/actions'
import * as notificationActions from '../../notification/actions'
import * as sessionReviewingActions from '../../reviewSessionPagesList/actions'
import { actions as towerSideProfileActions } from '../../towerSideProfile'
import { actions as twoDLineDrawingActions } from '../../2dLineDrawing'
import { actions as classificationAnnotationsActions } from '../../classificationAnnotationsPagesTimeline'

import { getGalleryItem } from '../selectors'
import { getIsCurrentUserViewer } from '../../../users/selectors'

import GalleryItem from '../components/GalleryItem'
import { accountSelectors } from '../../account'
import { contentStateSelectors } from '../../shared/content'
import { presentationSelectors } from '../../presentation'
import { splatActions } from '@tabeeb/modules/splatModel'

class GalleryItemContainer extends Component {
  constructor(props) {
    super(props)

    this.itemActions = {
      loaded: this._handleThumbnailLoaded,
      select: this._handleSelectGalleryItem,
      delete: this._handleDeleteGalleryItem,
      rename: this._handleRenameGalleryItem,
      validate: this._handleRenameValidation,
    }

    this.dragAndDropActions = {
      onDragStart: this._handleDragStart,
      onDragEnd: this._handleDragEnd,
    }

    this.customVideoActions = {
      addItem: this._handleAddItemToCustomVideo,
    }

    this.spatialModelActions = {
      addItem: this._handleAddItemToSpatialModel,
    }

    this.threedeeModelActions = {
      addItem: this._handleAddItemTo3dModel,
    }

    this.bentleyModelActions = {
      addItem: this._handleAddItemToBentleyModel,
    }

    this.bentleyAnalyticsReportActions = {
      addItem: this._handleAddItemToBentleyAnalyticsReport,
    }

    this.sessionSharingActions = {
      addItem: this._handleAddItemToSessionSharing,
    }

    this.sessionReviewingActions = {
      addItem: this._handleAddItemToSessionReviewing,
    }

    this.structureModelActions = {
      addItem: this._handleAddItemToStructureModel,
    }

    this.towerSideProfileActions = {
      addItem: this._handleAddItemToTowerSideProfile,
    }

    this.twoDLineDrawingActions = {
      addItem: this._handleAddItemTo2dLineDrawing,
    }

    this.classificationAnnotationsActions = {
      addItem: this._handleAddItemToClassificationAnnotationsPages,
    }

    this.selectionActions = {
      addItem: this._handleAddItemToSelection,
      removeItem: this._handleRemoveItemFromSelection,
    }

    this.splatModelActions = {
      addItem: this._handleAddItemToSplatModel,
    }
  }

  _handleThumbnailLoaded = () => {
    const {
      item,
      actions: { setGalleryItemThumbnailIsLoaded },
    } = this.props

    setGalleryItemThumbnailIsLoaded({ pageId: item.id })
  }

  _handleSelectGalleryItem = () => {
    const {
      item,
      actions: { onSelectGalleryItem },
    } = this.props

    onSelectGalleryItem({ pageId: item.id })
  }

  _handleDeleteGalleryItem = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      item,
      actions: { setDeleteGalleryItem },
    } = this.props

    setDeleteGalleryItem(item.id)
  }

  _handleRenameGalleryItem = (e) => {
    const {
      item,
      actions: { renameGalleryItemRequest },
    } = this.props

    const newName = e.target.value.trim()
    if (newName.length && newName !== item.thumbnailCaption) {
      renameGalleryItemRequest({ pageId: item.id, folderId: item.folderId, name: newName })
    }
  }

  _handleRenameValidation = (newValue, oldValue) => {
    if (newValue === oldValue) return false
    const valueLength = newValue.trim().length
    if (valueLength === 0 || valueLength > 256) {
      this.props.notificationActions.onAddErrorNotification({
        message: 'Page name cannot be empty or exceed 256 characters.',
      })
      return false
    }
    return true
  }

  _handleAddItemToSelection = () => {
    const {
      item,
      actions: { onAddPageToSelection },
    } = this.props

    onAddPageToSelection(item.id)
  }

  _handleRemoveItemFromSelection = () => {
    const {
      item,
      actions: { onRemovePageFromSelection },
    } = this.props

    onRemovePageFromSelection(item.id)
  }

  _handleDragStart = (e) => {
    if (!e.target.draggable) {
      e.preventDefault()
      e.stopPropagation()

      return
    }

    const {
      item,
      actions: { onStartDraggingPages },
    } = this.props

    onStartDraggingPages({ pageId: item.id, e })
  }

  _handleDragEnd = () => {
    const {
      actions: { clearSelection },
    } = this.props

    clearSelection()
  }

  _handleAddItemToSpatialModel = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      item,
      spatialModelActions: { addImageToModel },
    } = this.props

    addImageToModel(item)
  }

  _handleAddItemToCustomVideo = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      item,
      customVideoActions: { addTimelineItem },
    } = this.props

    addTimelineItem(item)
  }

  _handleAddItemTo3dModel = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      item,
      threedeeModelActions: { addImageTo3dModel },
    } = this.props

    addImageTo3dModel(item)
  }

  _handleAddItemToBentleyModel = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      item,
      bentleyModelActions: { addImageToBentleyModel },
    } = this.props

    addImageToBentleyModel(item)
  }

  _handleAddItemToBentleyAnalyticsReport = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      item,
      bentleyAnalyticsReportActions: { addModelToBentleyAnalyticsReport },
    } = this.props

    addModelToBentleyAnalyticsReport(item)
  }

  _handleAddItemToSessionSharing = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      item,
      sessionSharingActions: { addPageForSharing },
    } = this.props

    addPageForSharing(item)
  }

  _handleAddItemToSessionReviewing = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      item,
      sessionReviewingActions: { addPageForReviewing },
    } = this.props

    addPageForReviewing(item)
  }

  _handleAddItemToStructureModel = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      item,
      structureModelActions: { addImageToStructureModel },
    } = this.props

    addImageToStructureModel(item)
  }

  _handleAddItemToTowerSideProfile = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      item,
      towerSideProfileActions: { addPageToTowerSideProfile },
    } = this.props

    addPageToTowerSideProfile(item)
  }

  _handleAddItemTo2dLineDrawing = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      item,
      twoDLineDrawingActions: { addPageTo2dLineDrawing },
    } = this.props

    addPageTo2dLineDrawing(item)
  }

  _handleAddItemToClassificationAnnotationsPages = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      item,
      classificationAnnotationsActions: { addPageToClassificationAnnotationsPages },
    } = this.props

    addPageToClassificationAnnotationsPages(item)
  }

  _handleAddItemToSplatModel = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      item,
      splatModelActions: { addImageToSplatModel },
    } = this.props

    addImageToSplatModel(item)
  }

  render() {
    const {
      selectedGalleryItemId,
      is3dModelOpen,
      isBentleyModelOpen,
      isBentleyAnalyticsReportOpen,
      isCustomVideoOpen,
      isSpatialModelOpen,
      isSessionSharingPagesListOpen,
      isStructureModelOpen,
      isSessionReviewingPagesListOpen,
      isTowerSideProfileOpen,
      is2dLineDrawingOpen,
      isClassificationAnnotationsOpen,
      presenterId,
      currentUserId,
      isCallStarted,
      index,
      listRef,
      setSelectedItemRef,
      item,
      isGalleryExpanded,
      isGalleryExpansionEnabled,
      isViewer,
      canAddPointCloudPageToBentleyAnalyticsReport,
      isSplatModelOpen,
    } = this.props

    const props = {
      itemActions: this.itemActions,
      selectionActions: this.selectionActions,
      dragAndDropActions: this.dragAndDropActions,
      customVideoActions: this.customVideoActions,
      spatialModelActions: this.spatialModelActions,
      threedeeModelActions: this.threedeeModelActions,
      bentleyModelActions: this.bentleyModelActions,
      bentleyAnalyticsReportActions: this.bentleyAnalyticsReportActions,
      sessionSharingActions: this.sessionSharingActions,
      structureModelActions: this.structureModelActions,
      sessionReviewingActions: this.sessionReviewingActions,
      towerSideProfileActions: this.towerSideProfileActions,
      twoDLineDrawingActions: this.twoDLineDrawingActions,
      classificationAnnotationsActions: this.classificationAnnotationsActions,
      splatModelActions: this.splatModelActions,

      isCustomVideoOpen,
      isSpatialModelOpen,
      is3dModelOpen,
      isBentleyModelOpen,
      isBentleyAnalyticsReportOpen,
      isSessionSharingPagesListOpen,
      isSessionReviewingPagesListOpen,
      isStructureModelOpen,
      isTowerSideProfileOpen,
      is2dLineDrawingOpen,
      isClassificationAnnotationsOpen,
      isSplatModelOpen,

      isSelectedInGallery: selectedGalleryItemId == item.id,
      isAvailable: !isCallStarted || currentUserId === presenterId,

      index,
      listRef,
      setSelectedItemRef,

      isExpanded: isGalleryExpanded && isGalleryExpansionEnabled,

      isViewer,
      canAddPointCloudPageToBentleyAnalyticsReport,

      ...item,
    }

    return <GalleryItem {...props} />
  }
}

GalleryItemContainer.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,

  selectedGalleryItemId: PropTypes.number,
  isCustomVideoOpen: PropTypes.bool,
  isSpatialModelOpen: PropTypes.bool,
  is3dModelOpen: PropTypes.bool,
  isBentleyModelOpen: PropTypes.bool,
  isBentleyAnalyticsReportOpen: PropTypes.bool,
  isSessionSharingPagesListOpen: PropTypes.bool,
  isStructureModelOpen: PropTypes.bool,
  isSessionReviewingPagesListOpen: PropTypes.bool,
  isTowerSideProfileOpen: PropTypes.bool,
  is2dLineDrawingOpen: PropTypes.bool,
  isClassificationAnnotationsOpen: PropTypes.bool,
  isSplatModelOpen: PropTypes.bool,

  ownerId: PropTypes.number.isRequired,
  presenterId: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
  isCallStarted: PropTypes.bool.isRequired,

  listRef: PropTypes.object.isRequired,
  setSelectedItemRef: PropTypes.func.isRequired,

  isGalleryExpanded: PropTypes.bool.isRequired,
  isGalleryExpansionEnabled: PropTypes.bool.isRequired,

  isViewer: PropTypes.bool.isRequired,

  actions: PropTypes.shape({
    clearSelection: PropTypes.func.isRequired,
    onAddPageToSelection: PropTypes.func.isRequired,
    onRemovePageFromSelection: PropTypes.func.isRequired,
    onSelectGalleryItem: PropTypes.func.isRequired,
    onStartDraggingPages: PropTypes.func.isRequired,
    renameGalleryItemRequest: PropTypes.func.isRequired,
    selectGalleryItem: PropTypes.func.isRequired,
    setDeleteGalleryItem: PropTypes.func.isRequired,
    setGalleryItemThumbnailIsLoaded: PropTypes.func.isRequired,
  }).isRequired,
  customVideoActions: PropTypes.shape({
    addTimelineItem: PropTypes.func.isRequired,
  }).isRequired,
  spatialModelActions: PropTypes.shape({
    addImageToModel: PropTypes.func.isRequired,
  }).isRequired,
  threedeeModelActions: PropTypes.shape({
    addImageTo3dModel: PropTypes.func.isRequired,
  }).isRequired,
  bentleyModelActions: PropTypes.shape({
    addImageToBentleyModel: PropTypes.func.isRequired,
  }).isRequired,
  bentleyAnalyticsReportActions: PropTypes.shape({
    addModelToBentleyAnalyticsReport: PropTypes.func.isRequired,
  }).isRequired,
  notificationActions: PropTypes.shape({
    onAddErrorNotification: PropTypes.func.isRequired,
  }).isRequired,
  sessionSharingActions: PropTypes.shape({
    addPageForSharing: PropTypes.func.isRequired,
  }).isRequired,
  structureModelActions: PropTypes.shape({
    addImageToStructureModel: PropTypes.func.isRequired,
  }).isRequired,
  towerSideProfileActions: PropTypes.shape({
    addPageToTowerSideProfile: PropTypes.func.isRequired,
  }).isRequired,
  twoDLineDrawingActions: PropTypes.shape({
    addPageTo2dLineDrawing: PropTypes.func.isRequired,
  }).isRequired,
  classificationAnnotationsActions: PropTypes.shape({
    addPageToClassificationAnnotationsPages: PropTypes.func.isRequired,
  }).isRequired,
  sessionReviewingActions: PropTypes.shape({
    addPageForReviewing: PropTypes.func.isRequired,
  }).isRequired,
  splatModelActions: PropTypes.shape({
    addImageToSplatModel: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state, props) => {
  const {
    gallery,
    customVideo,
    spatialModel,
    threedeeModel,
    bentleyModel,
    bentleyAnalyticsReport,
    navbar,
    appConfigState,
    structureModel,
    towerSideProfile,
    twoDLineDrawing,
    classificationAnnotationsPagesTimeline,
    splatModel,
  } = state

  return {
    item: getGalleryItem(state, props),
    selectedGalleryItemId: gallery.galleryState.selectedGalleryItemId,
    isCustomVideoOpen:
      customVideo.isTimelineOpen && navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.CustomVideo,
    isSpatialModelOpen:
      spatialModel.isTimelineOpen && navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.SpatialView,
    is3dModelOpen:
      threedeeModel.isTimelineOpen && navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.ThreeDModel,
    isBentleyModelOpen:
      bentleyModel.isTimelineOpen && navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.BentleyModel,
    isBentleyAnalyticsReportOpen:
      bentleyAnalyticsReport.isTimelineOpen &&
      navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.BentleyAnalyticsReport,
    isSessionSharingPagesListOpen:
      state.shareSessionPagesList.isTimelineOpen &&
      state.navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.ShareSession,
    isStructureModelOpen:
      structureModel.isTimelineOpen && navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.StructureModel,
    isTowerSideProfileOpen:
      towerSideProfile.isTimelineOpen && navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.TowerSideProfile,
    is2dLineDrawingOpen:
      twoDLineDrawing.isTimelineOpen && navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.TwoDLineDrawing,
    isClassificationAnnotationsOpen:
      classificationAnnotationsPagesTimeline.isTimelineOpen &&
      navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.ClassificationAnnotations,
    isSplatModelOpen: splatModel.isTimelineOpen && navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.SplatModel,
    presenterId: contentStateSelectors.getPresenterId(state),
    currentUserId: accountSelectors.getCurrentUserId(state),
    isCallStarted: presentationSelectors.getIsCallStarted(state),
    ownerId: contentStateSelectors.getOwnerId(state),
    isGalleryExpanded: gallery.isGalleryExpanded,
    isGalleryExpansionEnabled: appConfigState.isGalleryExpansionEnabled,
    isSessionReviewingPagesListOpen:
      state.reviewSessionPagesList.isTimelineOpen &&
      state.navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.ReviewSession,
    isViewer: getIsCurrentUserViewer(state),
    canAddPointCloudPageToBentleyAnalyticsReport: bentleyAnalyticsReport.modelsList.length < 1,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
    customVideoActions: bindActionCreators(customVideoActions, dispatch),
    spatialModelActions: bindActionCreators(spatialModelActions, dispatch),
    threedeeModelActions: bindActionCreators(threedeeModelActions, dispatch),
    bentleyModelActions: bindActionCreators(bentleyModelActions, dispatch),
    bentleyAnalyticsReportActions: bindActionCreators(bentleyAnalyticsReportActions, dispatch),
    sessionSharingActions: bindActionCreators(sessionSharingActions, dispatch),
    structureModelActions: bindActionCreators(structureModelActions, dispatch),
    towerSideProfileActions: bindActionCreators(towerSideProfileActions, dispatch),
    twoDLineDrawingActions: bindActionCreators(twoDLineDrawingActions, dispatch),
    classificationAnnotationsActions: bindActionCreators(classificationAnnotationsActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    sessionReviewingActions: bindActionCreators(sessionReviewingActions, dispatch),
    splatModelActions: bindActionCreators(splatActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryItemContainer)
