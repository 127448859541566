import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Vector3 } from 'three'

import { TransformControls } from '@react-three/drei'

import { getTransformationState } from '@tabeeb/modules/pointCloud/selectors'
import { enableTransformation, disableTransformation } from '@tabeeb/modules/pointCloud/actions'

const Mode = {
  TRANSLATE: 'translate',
  ROTATE: 'rotate',
  SCALE: 'scale',
}

const TransformationControls = ({ object, onRotate, onScale, onTranslate }) => {
  const dispatch = useDispatch()
  const { mode } = useSelector(getTransformationState)

  const [{ position: initialPosition }, setInitialState] = useState({
    position: new Vector3(0, 0, 0),
  })

  useEffect(() => {
    dispatch(enableTransformation())

    return () => {
      dispatch(disableTransformation())
    }
  }, [dispatch])

  const onMouseDown = useCallback(
    (e) => {
      const target = e.target.object

      if (mode === Mode.TRANSLATE) {
        setInitialState((prevState) => ({
          ...prevState,
          position: target.position.clone(),
        }))
      }
    },
    [mode]
  )

  const onMouseUp = useCallback(
    (e) => {
      const target = e.target.object

      if (mode === Mode.TRANSLATE) {
        const newPosition = target.position.clone()

        onTranslate(newPosition.sub(initialPosition))
      }

      if (mode === Mode.ROTATE) {
        onRotate(target.rotation.clone())
      }

      if (mode === Mode.SCALE) {
        onScale(target.scale.clone())
      }
    },
    [initialPosition, mode, onRotate, onScale, onTranslate]
  )

  return <TransformControls space='local' object={object} mode={mode} onMouseDown={onMouseDown} onMouseUp={onMouseUp} />
}

TransformationControls.defaultProps = {
  onRotate: () => {},
  onScale: () => {},
  onTranslate: () => {},
}

TransformationControls.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  object: PropTypes.object.isRequired,
  onRotate: PropTypes.func,
  onScale: PropTypes.func,
  onTranslate: PropTypes.func,
}

export default TransformationControls
