import { all } from 'redux-saga/effects'

import { sessionTabsSaga } from '@tabeeb/modules/sessionTabs'
import { ContentSaga } from 'modules/shared/content'
import { GallerySagas } from 'modules/gallery'
import { permissionsSagas } from '@tabeeb/modules/permissions'
import { callNotificationSagas } from '@tabeeb/modules/callNotification'
import { remindersSagas } from '@tabeeb/modules/reminders'
import { discussionsSaga } from '@tabeeb/modules/discussion'
import { contentMetadataSaga } from '@tabeeb/modules/contentMetadata'
import { calendarSagas } from '@tabeeb/modules/calendar'
import { contentTrashBinSagas } from '@tabeeb/modules/contentTrashBin'
import geoSearchSagas from '@tabeeb/modules/geosearch/sagas'
import { classificationAnnotationsPagesTimelineSagas } from '@tabeeb/modules/classificationAnnotationsPagesTimeline'
import watchRequest from './watchRequest'
import watchParallelRequests from './watchParallelRequests'
import watchUploadFiles from './watchUploadFiles'

import { accountSagas } from '../../modules/account'

import { RecordingSagas } from '../../modules/recording'
import { ActivitySagas } from '../../modules/activities'
import { FormsSagas } from '../../modules/forms'
import { PresentationSagas } from '../../modules/presentation'
import { PlayerToolbarSaga } from '../../modules/playerToolbar'
import { navbarSaga } from '../../modules/navbar'
import { GridViewSagas } from '../../modules/gridView'

import { CustomVideoSagas } from '../../modules/customVideo'
import { PresentationToolbarSaga } from '../../modules/presentationToolbar'
import { usersSaga } from '../../users'
import { SessionsPageSagas } from '../../modules/sessionsPage'
import { FormsPageSagas } from '../../modules/formsPage'
import { chatSaga } from '../../modules/chat'
import { FormInfoPageSagas } from '../../modules/formInfoPage'
import { CreateFormPageSagas } from '../../modules/createFormPage'
import { playerSagas } from '../../modules/player'
import { AnnotationsSagas } from '../../modules/annotations'
import { ArtificialIntelligenceSagas } from '../../modules/artificialIntelligence'
import { TenantFormsSagas } from '../../modules/tenantForms'
import { SpatialModelSagas } from '../../modules/spatialModel'
import { TenantSessionsSagas } from '../../modules/tenantSessionsPage'
import { ContentViewerSagas } from '../../modules/contentViewer'
import { TenantConfigSagas } from '../../modules/tenantConfigPage'
import { PoliciesSagas } from '../../modules/policies'
import { ThreedeeModelSagas } from '../../modules/3dmodel'
import { ShareSessionPagesSagas } from '../../modules/shareSessionPagesList'
import { StructureModelSagas } from '../../modules/structureModel'
import { NotificationsPageSagas } from '../../modules/notificationsPage'
import adminUsersPageSaga from '../../modules/admin/users/sagas'
import { trackingSagas } from '../../modules/tracking'
import { ReviewSessionPagesSagas } from '../../modules/reviewSessionPagesList'
import { ContentReviewsSaga } from '../../modules/contentReviews'
import DevicesPageSaga from '../../modules/admin/devices/sagas'
import { compassSaga } from '../../modules/compass'
import { BillingSettingsSaga } from '../../modules/billingSettingsPage'
import { AnalyticsSaga } from '../../modules/powerBIReports'

import { appConfigStateSagas } from '../../modules/appConfigState'
import { signalrSagas } from '../../modules/signalr'
import { whiteboardSagas } from '../../modules/whiteboard'
import { vqaPolicySagas } from '../../modules/vqaPolicy'
import { towerSideProfileSagas } from '@tabeeb/modules/towerSideProfile'
import { twoDLineDrawingSagas } from '@tabeeb/modules/2dLineDrawing'
import { healthDataSagas } from '@tabeeb/modules/healthData'
import { mobileWhiteboardSagas } from '@tabeeb/modules/mobileWhiteboard'
import { BentleyModelSagas } from '../../modules/bentleyModel'
import { SplatModelSagas } from '../../modules/splatModel'
import { BentleyAnalyticsReportSagas } from '../../modules/bentleyAnalyticsReport'
import { ContentSharingsSaga } from '../../modules/contentSharings'
import { aiReportSagas } from '@tabeeb/modules/aiReport'
import modelTrainingSagas from '../../modules/modelTraining/sagas'
import { powerBIReportProvidersSaga } from '@tabeeb/modules/powerBIReportProviders'

function* rootSaga() {
  yield all([
    watchRequest(),
    watchParallelRequests(),
    watchUploadFiles(),
    accountSagas(),
    sessionTabsSaga(),
    ContentSaga(),
    GallerySagas(),
    RecordingSagas(),
    ActivitySagas(),
    FormsSagas(),
    PresentationSagas(),
    PlayerToolbarSaga(),
    CustomVideoSagas(),
    PresentationToolbarSaga(),
    chatSaga(),
    usersSaga(),
    SessionsPageSagas(),
    FormsPageSagas(),
    FormInfoPageSagas(),
    CreateFormPageSagas(),
    playerSagas(),
    AnnotationsSagas(),
    ArtificialIntelligenceSagas(),
    TenantFormsSagas(),
    SpatialModelSagas(),
    TenantConfigSagas(),
    TenantSessionsSagas(),
    PoliciesSagas(),
    ContentViewerSagas(),
    ThreedeeModelSagas(),
    ShareSessionPagesSagas(),
    StructureModelSagas(),
    NotificationsPageSagas(),
    navbarSaga(),
    whiteboardSagas(),
    appConfigStateSagas(),
    signalrSagas(),
    trackingSagas(),
    adminUsersPageSaga(),
    ReviewSessionPagesSagas(),
    ContentReviewsSaga(),
    DevicesPageSaga(),
    AnalyticsSaga(),
    compassSaga(),
    BillingSettingsSaga(),
    permissionsSagas(),
    remindersSagas(),
    discussionsSaga(),
    callNotificationSagas(),
    GridViewSagas(),
    contentMetadataSaga(),
    vqaPolicySagas(),
    calendarSagas(),
    towerSideProfileSagas(),
    contentTrashBinSagas(),
    twoDLineDrawingSagas(),
    healthDataSagas(),
    mobileWhiteboardSagas(),
    BentleyModelSagas(),
    SplatModelSagas(),
    BentleyAnalyticsReportSagas(),
    ContentSharingsSaga(),
    modelTrainingSagas(),
    aiReportSagas(),
    classificationAnnotationsPagesTimelineSagas(),
    powerBIReportProvidersSaga(),
  ])
}

export default rootSaga
