import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'

import { callApiAsync } from '@tabeeb/state/sagas/watchRequest'
import { trainingMaterialTitleFormatter } from '@tabeeb/shared/utils/text'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'

import { deleteTrainingMaterial, getTrainingMaterials } from '../actions'

const EMPTY_LIST = {
  Items: [],
  TotalCount: 0,
}

export default ({ enabled, skip = 0, take = 50, search = null, contentId = null, attach = false, onError }) => {
  const dispatch = useDispatch()

  const payload = useMemo(
    () => ({
      skip,
      take,
      search,
      contentId,
      attach,
    }),
    [attach, contentId, search, skip, take]
  )

  const {
    loading,
    loaded,
    response: { Items: trainingMaterials, TotalCount: totalCount },
    onMutated,
  } = useApiRequest({
    request: getTrainingMaterials.request,
    defaultResponse: EMPTY_LIST,
    enabled,
    payload,
    onError,
  })

  const onDelete = useCallback(
    async (trainingMaterial) => {
      try {
        await callApiAsync(deleteTrainingMaterial.request({ id: trainingMaterial.Id }))
        onMutated()
      } catch (error) {
        dispatch(
          onAddErrorNotification({
            message: trainingMaterialTitleFormatter.format('Failed to delete training material'),
          })
        )
      }
    },
    [dispatch, onMutated]
  )

  return { loading, loaded, totalCount, trainingMaterials, onDelete, onReload: onMutated }
}
