import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'

import { FetchStatus } from '@tabeeb/enums'
import { callApiAsync } from '@tabeeb/state/sagas/watchRequest'

const PAGE_SIZE = 20

export default ({ enabled = true, request, payload }) => {
  const [{ status, items, hasMore }, setState] = useState({
    status: FetchStatus.Idle,
    items: [],
    hasMore: true,
  })

  useEffect(() => {
    setState({
      status: FetchStatus.Idle,
      items: [],
      hasMore: true,
    })
  }, [payload, enabled])

  const fetchData = useCallback(async () => {
    if (!enabled || status === FetchStatus.Loading || !hasMore) {
      return
    }

    setState((prevState) => ({ ...prevState, status: FetchStatus.Loading }))

    const {
      response: { data: newItems },
    } = await callApiAsync(
      request({
        ...payload,
        pageNumber: Math.ceil(items.length / PAGE_SIZE) + 1,
        pageSize: PAGE_SIZE,
      })
    )

    setState((prevState) => ({
      status: FetchStatus.Loaded,
      items: _.uniqBy([...prevState.items, ...newItems], (item) => item.Id),
      hasMore: newItems.length === PAGE_SIZE,
    }))
  }, [enabled, hasMore, items.length, payload, request, status])

  const onLoadMore = useCallback(() => {
    fetchData()
  }, [fetchData])

  const onReload = useCallback(() => {
    setState({
      status: FetchStatus.Idle,
      items: [],
      hasMore: true,
    })
  }, [])

  return {
    status,
    loading: status === FetchStatus.Loading,
    loaded: status === FetchStatus.Loaded,
    items,
    hasMore,
    onLoadMore,
    onReload,
  }
}
