import { useCallback, memo, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { replace } from 'connected-react-router'

import Routes from '@tabeeb/routes'
import { callApiAsync } from '@tabeeb/state/sagas/watchRequest'
import { useQuery } from '@tabeeb/shared/utils/hooks'
import { trainingMaterialTitleFormatter } from '@tabeeb/shared/utils/text'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

import { createTrainingMaterial } from '@tabeeb/modules/articles/actions'
import { ArticleContentType, ArticleReadScope, ArticlePublicationStatus } from '@tabeeb/modules/articles/enums'

import TrainingMaterialEditorPage from '../TrainingMaterialEditorPage'

const TrainingMaterialCreatePage = () => {
  const dispatch = useDispatch()

  const type = useQuery().get('type')

  const contentType = useMemo(() => {
    const parsed = parseInt(type, 10)
    if (Object.values(ArticleContentType).includes(parsed)) {
      return parsed
    }

    return ArticleContentType.Html
  }, [type])

  const onSubmit = useCallback(
    async (trainingMaterial) => {
      try {
        await callApiAsync(createTrainingMaterial.request(trainingMaterial))
        dispatch(
          onAddSuccessNotification({
            message: trainingMaterialTitleFormatter.format('Training material successfully created'),
          })
        )
        dispatch(replace(Routes.trainingMaterials))
      } catch {
        dispatch(
          onAddErrorNotification({
            message: trainingMaterialTitleFormatter.format('Failed to create training material'),
          })
        )
      }
    },
    [dispatch]
  )

  const initialValues = useMemo(
    () => ({
      Name: trainingMaterialTitleFormatter.format('New training material'),
      Content: {
        Type: contentType,
        Content: '',
      },
      ReadScope: ArticleReadScope.Content,
      Status: ArticlePublicationStatus.Draft,
    }),
    [contentType]
  )

  return (
    <TrainingMaterialEditorPage
      initial={initialValues}
      title={trainingMaterialTitleFormatter.format('Create training material')}
      onSubmit={onSubmit}
    />
  )
}

export default memo(TrainingMaterialCreatePage)
