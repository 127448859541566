import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Euler, Quaternion, Vector3 } from 'three'

import { AnnotationType } from '@tabeeb/enums'
import {
  getDrawingAnnotationType,
  getIsDrawingEnabled,
  getSelectedModel,
  getWorldRotation,
} from '@tabeeb/modules/pointCloud/selectors'
import { addAnnotationRequest } from '@tabeeb/modules/annotations/actions'

import { getSelectedGalleryItemId } from '@tabeeb/modules/gallery/selectors'
import { getSelectedColor, getWidth } from '@tabeeb/modules/playerToolbar/selectors'

import Raycast from '../Raycast'
import PendingAnnotation from '../PendingAnnotation'

const trimTempPoint = (annotation) => {
  if (
    annotation.Type === AnnotationType.Area ||
    annotation.Type === AnnotationType.Distance ||
    annotation.Type === AnnotationType.Height
  ) {
    return {
      ...annotation,
      Points: annotation.Points.slice(0, -1),
    }
  }

  return annotation
}

const Drawer = () => {
  const dispatch = useDispatch()

  const worldRotation = useSelector(getWorldRotation)
  const pageId = useSelector(getSelectedGalleryItemId)
  const enabled = useSelector(getIsDrawingEnabled)
  const annotationType = useSelector(getDrawingAnnotationType)
  const model = useSelector(getSelectedModel)
  const color = useSelector(getSelectedColor)
  const width = useSelector(getWidth)

  const [annotation, setAnnotation] = useState(null)

  useEffect(() => {
    if (!enabled) {
      setAnnotation(null)
    }
  }, [enabled])

  const onSubmitCreation = useCallback(() => {
    const annotationToCreate = trimTempPoint(annotation)

    dispatch(addAnnotationRequest(annotationToCreate))
    setAnnotation(null)
  }, [annotation, dispatch])

  const onAddPoint = useCallback(
    (point) => {
      setAnnotation((prevAnnotation) => {
        if (prevAnnotation === null) {
          switch (annotationType) {
            case AnnotationType.Model:
              return {
                Anchor: {
                  X: point.x,
                  Y: point.y,
                  Z: point.z,
                },
                PageId: pageId,
                Color: color,
                Type: AnnotationType.Model,
                Name: model.Name,
                Azimuth: 0,
                Tilt: 90,
                Url: model.Url,
              }
            case AnnotationType.Box:
            case AnnotationType.Cylinder:
              return {
                isAiHotspot: true,
                Anchor: {
                  X: point.x,
                  Y: point.y,
                  Z: point.z,
                },
                Rotation: {
                  X: 0,
                  Y: 0,
                  Z: 0,
                },
                Scale: {
                  X: 0.5,
                  Y: 0.5,
                  Z: 0.5,
                },
                PageId: pageId,
                Color: color,
                Type: annotationType,
                Width: width,
              }
            case AnnotationType.Area:
            case AnnotationType.Distance:
            case AnnotationType.Height:
              return {
                Points: [
                  {
                    X: point.x,
                    Y: point.y,
                    Z: point.z,
                  },
                  {
                    X: point.x,
                    Y: point.y,
                    Z: point.z,
                  },
                ],
                PageId: pageId,
                Color: color,
                Type: annotationType,
                Width: width,
              }
            default:
              return null
          }
        }

        if (prevAnnotation.Type === AnnotationType.Model) {
          return {
            ...prevAnnotation,
            Anchor: {
              X: point.x,
              Y: point.y,
              Z: point.z,
            },
          }
        }

        if (prevAnnotation.Type === AnnotationType.Box || prevAnnotation.Type === AnnotationType.Cylinder) {
          if (!prevAnnotation.Configured) {
            return {
              ...prevAnnotation,
              Configured: true,
            }
          }

          return prevAnnotation
        }

        if (
          prevAnnotation.Type === AnnotationType.Distance ||
          prevAnnotation.Type === AnnotationType.Height ||
          prevAnnotation.Type === AnnotationType.Area
        ) {
          return {
            ...prevAnnotation,
            Points: [
              ...prevAnnotation.Points,
              {
                X: point.x,
                Y: point.y,
                Z: point.z,
              },
            ],
          }
        }

        return null
      })
    },
    [annotationType, color, model.Name, model.Url, pageId, width]
  )

  const onUpdatePoint = useCallback(
    (point) => {
      setAnnotation((prevAnnotation) => {
        if (prevAnnotation === null) {
          return null
        }

        if (
          prevAnnotation.Type === AnnotationType.Distance ||
          prevAnnotation.Type === AnnotationType.Height ||
          prevAnnotation.Type === AnnotationType.Area
        ) {
          return {
            ...prevAnnotation,
            Points: [
              ...prevAnnotation.Points.slice(0, -1),
              {
                X: point.x,
                Y: point.y,
                Z: point.z,
              },
            ],
          }
        }

        if (prevAnnotation.Type === AnnotationType.Box || prevAnnotation.Type === AnnotationType.Cylinder) {
          if (prevAnnotation.Configured) {
            return prevAnnotation
          }

          const start = new Vector3(prevAnnotation.Anchor.X, prevAnnotation.Anchor.Y, prevAnnotation.Anchor.Z)
          const end = new Vector3(point.x, point.y, point.z)

          const height = start.distanceTo(end)
          const direction = new Vector3().subVectors(end, start).applyEuler(worldRotation).normalize()

          const quaternion = new Quaternion()
          quaternion.setFromUnitVectors(new Vector3(0, 1, 0), direction)

          const euler = new Euler()
          euler.setFromQuaternion(quaternion)

          return {
            ...prevAnnotation,
            Scale: {
              ...prevAnnotation.Scale,
              Y: height,
            },
            Rotation: {
              X: euler.x,
              Y: euler.y,
              Z: euler.z,
            },
          }
        }

        return null
      })
    },
    [worldRotation]
  )

  return (
    <>
      <Raycast enabled={enabled} onAddPoint={onAddPoint} onUpdatePoint={onUpdatePoint} />
      {annotation && <PendingAnnotation annotation={annotation} onSubmit={onSubmitCreation} />}
    </>
  )
}

export default memo(Drawer)
