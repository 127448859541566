import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Vector3 } from 'three'

import { TransformControls } from '@react-three/drei'

import { useTransformControls, useWorldRotation } from '../../../hooks'
import Model from '../../Model'

const ModelAnnotation = ({ annotation }) => {
  const worldRotation = useWorldRotation()

  const baseUrl = annotation.Url.split('?')[0]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const url = useMemo(() => annotation.Url, [baseUrl])

  const position = useMemo(
    () => new Vector3(annotation.Anchor.X, annotation.Anchor.Y, annotation.Anchor.Z).applyEuler(worldRotation),
    [annotation.Anchor.X, annotation.Anchor.Y, annotation.Anchor.Z, worldRotation]
  )

  const { enabled, object, onSelect, onUpdated } = useTransformControls({ annotation })

  return (
    <group visible={!annotation.Hidden}>
      {enabled && <TransformControls object={object} onMouseUp={onUpdated} />}
      <Model azimuth={annotation.Azimuth} url={url} position={position} tilt={annotation.Tilt} onClick={onSelect} />
    </group>
  )
}

ModelAnnotation.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Anchor: PropTypes.shape({
      X: PropTypes.number.isRequired,
      Y: PropTypes.number.isRequired,
      Z: PropTypes.number.isRequired,
    }).isRequired,
    Azimuth: PropTypes.number.isRequired,
    Tilt: PropTypes.number.isRequired,
    Hidden: PropTypes.bool,
    Url: PropTypes.string.isRequired,
  }).isRequired,
}

export default ModelAnnotation
