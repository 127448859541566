import { call, put, takeEvery, select, fork, take, cancel, cancelled } from 'redux-saga/effects'
import _ from 'lodash'

import selector from 'modules/shared/utils/selector'
import ApiMethods from 'modules/shared/utils/API'
import ApiService from '../../services/API'

import { applicationStore } from '../createStore'

export function callAPIPromise(action, options = { callResultActions: true, abortControllerSignal: null }) {
  const state = applicationStore.getState()
  const apiHostName = selector.getApiUrl(state)
  const accessToken = selector.getAccessToken(state)
  const data = ApiMethods[_.camelCase(action.type)](action.payload)

  return new Promise((resolve, reject) => {
    ApiService({
      hostName: apiHostName,
      accessToken,
      data,
      signal: options?.abortControllerSignal,
    })
      .then((response) => {
        const successModel = {
          type: action.type.replace('_REQUEST', '_SUCCESS'),
          response,
          payload: action.payload,
        }

        if (options.callResultActions) {
          applicationStore.dispatch(successModel)
        }

        resolve(successModel)
      })
      .catch((e) => {
        const errorModel = {
          type: action.type.replace('_REQUEST', '_FAILED'),
          payload: action.payload,
          message: e.statusText,
          status: e.status,
          response: e.response,
        }

        if (options.callResultActions) {
          applicationStore.dispatch(errorModel)
        }

        reject(errorModel)
      })
  })
}

export { callAPIPromise as callApiAsync }

export function* callAPI(action) {
  const abortController = new AbortController()
  const apiHostName = yield select(selector.getApiUrl)
  const accessToken = yield select(selector.getAccessToken)
  const data = ApiMethods[_.camelCase(action.type)](action.payload)
  try {
    const response = yield call(ApiService, {
      hostName: apiHostName,
      accessToken,
      data,
      signal: abortController.signal,
    })

    const newType = action.type.replace('_REQUEST', '_SUCCESS')
    yield put({ type: newType, response, payload: action.payload })
  } catch (e) {
    const errorModel = {
      type: action.type.replace('_REQUEST', '_FAILED'),
      payload: action.payload,
      message: e.statusText,
      status: e.status,
      response: e.response,
    }
    console.error(e)
    console.error(errorModel)
    yield put(errorModel)
  } finally {
    if (yield cancelled()) {
      abortController.abort()
    }
  }
}

function* cancellableCallApi(action) {
  const forkedCallApi = yield fork(callAPI, action)
  const cancelledType = action.type.replace('_REQUEST', '_CANCEL')

  yield take(cancelledType)
  yield cancel(forkedCallApi)
}

export default function* watchRequest() {
  yield takeEvery((action) => /^.*_REQUEST$/.test(action.type), cancellableCallApi)
}
