import { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Euler, Vector3 } from 'three'

import { AnnotationType } from '@tabeeb/enums'
import { getWorldRotation } from '@tabeeb/modules/pointCloud/selectors'
import { getAnnotationComponent } from '@tabeeb/modules/pointCloud/utils/components'

import Controls from '../Controls'

const getCanSubmitAnnotation = (annotation) => {
  if (annotation.Type === AnnotationType.Model) {
    return Boolean(annotation.Anchor)
  }

  if (annotation.Type === AnnotationType.Box || annotation.Type === AnnotationType.Cylinder) {
    return Boolean(annotation.Anchor) && Boolean(annotation.Configured)
  }

  if (annotation.Type === AnnotationType.Area) {
    return Boolean(annotation.Points?.length >= 4)
  }

  return Boolean(annotation.Points?.length >= 3)
}

const getControlsPosition = (annotation, worldRotation) => {
  if (annotation.Type === AnnotationType.Model) {
    return new Vector3(annotation.Anchor.X, annotation.Anchor.Y, annotation.Anchor.Z)
  }

  if (annotation.Type === AnnotationType.Box || annotation.Type === AnnotationType.Cylinder) {
    const position = new Vector3(annotation.Anchor.X, annotation.Anchor.Y, annotation.Anchor.Z)
    const size = new Vector3(annotation.Scale.X, annotation.Scale.Y, annotation.Scale.Z)
    const rotation = new Euler(
      annotation.Rotation.X - worldRotation.x,
      annotation.Rotation.Y - worldRotation.y,
      annotation.Rotation.Z - worldRotation.z
    )

    const end = new Vector3(0, size.y, 0).applyEuler(rotation).add(position)

    return end
  }

  if (annotation.Coordinates) {
    const point = annotation.Coordinates[annotation.Coordinates.length - 1].Start

    return new Vector3(point.X, point.Y, point.Z)
  }

  const point = annotation.Points[annotation.Points.length - 2]

  return new Vector3(point.X, point.Y, point.Z)
}

const PendingAnnotation = ({ annotation, onSubmit }) => {
  const worldRotation = useSelector(getWorldRotation)
  const canSubmit = getCanSubmitAnnotation(annotation)

  const Component = getAnnotationComponent(annotation.Type)
  if (!Component) {
    return null
  }

  return (
    <>
      {canSubmit && <Controls position={getControlsPosition(annotation, worldRotation)} onSubmit={onSubmit} />}
      <Component annotation={annotation} raycasting={false} hovered />
    </>
  )
}

PendingAnnotation.propTypes = {
  annotation: PropTypes.shape({
    Type: PropTypes.number.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default memo(PendingAnnotation)
