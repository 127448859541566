import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateContentAnnotation } from '@tabeeb/modules/annotations/actions'

import { getWorldRotationCompensation } from '../selectors'

const useTransformationControls = ({ annotation }) => {
  const dispatch = useDispatch()

  const worldRotationCompensation = useSelector(getWorldRotationCompensation)

  const onTranslate = useCallback(
    (offset) => {
      const projectedOffset = offset.clone().applyEuler(worldRotationCompensation)

      dispatch(
        updateContentAnnotation({
          annotation: {
            ...annotation,
            Anchor: {
              X: annotation.Anchor.X + projectedOffset.x,
              Y: annotation.Anchor.Y + projectedOffset.y,
              Z: annotation.Anchor.Z + projectedOffset.z,
            },
          },
        })
      )
    },
    [annotation, dispatch, worldRotationCompensation]
  )

  const onRotate = useCallback(
    (newRotation) => {
      dispatch(
        updateContentAnnotation({
          annotation: {
            ...annotation,
            Rotation: {
              X: newRotation.x,
              Y: newRotation.y,
              Z: newRotation.z,
            },
          },
        })
      )
    },
    [annotation, dispatch]
  )

  const onScale = useCallback(
    (newScale) => {
      dispatch(
        updateContentAnnotation({
          annotation: {
            ...annotation,
            Scale: {
              X: newScale.x,
              Y: newScale.y,
              Z: newScale.z,
            },
          },
        })
      )
    },
    [annotation, dispatch]
  )

  return useMemo(() => ({ onTranslate, onRotate, onScale }), [onTranslate, onRotate, onScale])
}

export default useTransformationControls
