import { Box, Tooltip, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const KeyIcon = ({ name }) => {
  return (
    <Tooltip title={`Press ${name} to enable`} placement='top'>
      <Box
        sx={{ border: '1px solid white', mr: 1.5, cursor: 'help' }}
        borderRadius={0.5}
        width={28}
        height={28}
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography fontSize={15} fontWeight={700} color='#ffffff'>
          {name}
        </Typography>
      </Box>
    </Tooltip>
  )
}

KeyIcon.propTypes = {
  name: PropTypes.string.isRequired,
}

export default KeyIcon
