import { reducer as formReducer } from 'redux-form'

import { navbarReducer as topNavBar } from 'modules/navbar'
import { ContentReducer as contentState } from 'modules/shared/content'
import { RecordingReducer as recording } from 'modules/recording'
import { chatReducer as chat } from 'modules/chat'
import { sessionTabsReducer } from '@tabeeb/modules/sessionTabs'
import gridView from '@tabeeb/modules/gridView/reducers'
import callNotification from '@tabeeb/modules/callNotification/reducers'
import { calendarReducer as calendar } from '@tabeeb/modules/calendar'
import billingSettingsPage from '@tabeeb/modules/billingSettingsPage/reducers'
import { permissionsReducer as permissions } from '@tabeeb/modules/permissions'
import { remindersReducer as reminders } from '@tabeeb/modules/reminders'
import { discussionsReducer as discussion } from '@tabeeb/modules/discussion'
import { contentMetadataReducer as contentMetadata } from '@tabeeb/modules/contentMetadata'
import { towerSideProfileReducer as towerSideProfile } from '@tabeeb/modules/towerSideProfile'
import { powerBIReducer as powerBI } from '@tabeeb/modules/powerBIReports'
import contentSharings from '@tabeeb/modules/contentSharings/reducers'
import { classificationAnnotationsPagesTimelineReducer as classificationAnnotationsPagesTimeline } from '@tabeeb/modules/classificationAnnotationsPagesTimeline'
import pointCloudGeoSearch from '@tabeeb/modules/pointCloudGeoSearch/reducers'
import { reducer as formSettings } from '@tabeeb/modules/formSettings'
import { twoDLineDrawingReducer as twoDLineDrawing } from '@tabeeb/modules/2dLineDrawing'
import { contentTrashBinReducer as contentTrashBin } from '@tabeeb/modules/contentTrashBin'
import { mobileWhiteboardReducer as mobileWhiteboard } from '@tabeeb/modules/mobileWhiteboard'
import { pointCloudReducer as pointCloud } from '@tabeeb/modules/pointCloud'
import healthData from '@tabeeb/modules/healthData/reducers'
import sncFilters from '@tabeeb/modules/sncFilters/reducers'
import { aiReportReducer as AIReport } from '@tabeeb/modules/aiReport'
import { reportTemplateEditorsReducer as reportTemplateEditor } from '@tabeeb/modules/reportTemplateEditor'
import { workflowsReducer as workflows } from '@tabeeb/modules/workflows'
import { utilityInfrastructureReducer as utilityInfrastructure } from '@tabeeb/modules/utilityInfrastructure'
import { powerBIReportProvidersReducer as powerBIReportProviders } from '@tabeeb/modules/powerBIReportProviders'
import { geosearchReducer as geosearch } from '../../modules/geosearch'
import { playerReducer as player } from '../../modules/player'

import activities from '../../modules/activities/reducers'
import articles from '../../modules/articles/reducers'
import forms from '../../modules/forms/reducers'
import gallery from '../../modules/gallery/reducers'
import fileUploads from '../../modules/fileUploads/reducers'
import users from '../../users/reducers/index'
import presentation from '../../modules/presentation/reducers'
import playerToolbar from '../../modules/playerToolbar/reducers'
import customVideo from '../../modules/customVideo/reducers'
import presentationToolbar from '../../modules/presentationToolbar/reducers'
import { notificationReducer as notification } from '../../modules/notification'
import sessionsPage from '../../modules/sessionsPage/reducers'
import formsPage from '../../modules/formsPage/reducers'
import tenantForms from '../../modules/tenantForms/reducers'
import tenantConfigPage from '../../modules/tenantConfigPage/reducers'
import formInfoPage from '../../modules/formInfoPage/reducers'
import createFormPage from '../../modules/createFormPage/reducers'
import artificialIntelligence from '../../modules/artificialIntelligence/reducers'
import spatialModel from '../../modules/spatialModel/reducers'
import threedeeModel from '../../modules/3dmodel/reducers'
import { splatReducer as splatModel } from '@tabeeb/modules/splatModel'

import contentViewer from '../../modules/contentViewer/reducers'
import bentleyModel from '../../modules/bentleyModel/reducers'
import bentleyAnalyticsReport from '../../modules/bentleyAnalyticsReport/reducers'

import tenantSessionsPage from '../../modules/tenantSessionsPage/reducers'
import policies from '../../modules/policies/reducers'
import shareSessionPagesList from '../../modules/shareSessionPagesList/reducers'
import { accountReducer as account } from '../../modules/account'
import structureModel from '../../modules/structureModel/reducers'
import admin from '../../modules/admin/adminReducer'
import notificationsPage from '../../modules/notificationsPage/reducers'
import { trackingReducer as tracking } from '../../modules/tracking'
import alertOwner from '../../modules/alertOwner/reducers'
import reviewSessionPagesList from '../../modules/reviewSessionPagesList/reducers'
import contentReviews from '../../modules/contentReviews/reducers'
import { compassReducer as compass } from '../../modules/compass'

import { appConfigStateReducer } from '../../modules/appConfigState'
import { signalrReducer } from '../../modules/signalr'

import { annotations } from '../../modules/annotations'
import { allFormsReducer as allForms } from '../../modules/allForms'

import { whiteboardReducer as whiteboard } from '../../modules/whiteboard'

import sessionState from './sessionState'
import deviceState from './deviceState'

import { vqaPolicyReducer as vqaPolicy } from '../../modules/vqaPolicy'
import modelTraining from '../../modules/modelTraining/reducers'

export default {
  account,
  activities,
  articles,
  admin,
  AIReport,
  alertOwner,
  allForms,
  annotations,
  appConfigState: appConfigStateReducer,
  artificialIntelligence,
  bentleyAnalyticsReport,
  bentleyModel,
  billingSettingsPage,
  calendar,
  callNotification,
  chat,
  classificationAnnotationsPagesTimeline,
  compass,
  contentMetadata,
  contentReviews,
  contentSharings,
  contentState,
  contentTrashBin,
  contentViewer,
  createFormPage,
  customVideo,
  deviceState,
  discussion,
  fileUploads,
  form: formReducer,
  formInfoPage,
  forms,
  formSettings,
  formsPage,
  gallery,
  gridView,
  healthData,
  mobileWhiteboard,
  modelTraining,
  navbar: sessionTabsReducer,
  notification,
  notificationsPage,
  permissions,
  player,
  playerToolbar,
  pointCloud,
  policies,
  powerBI,
  powerBIReportProviders,
  pointCloudGeoSearch,
  presentation,
  presentationToolbar,
  recording,
  reminders,
  reportTemplateEditor,
  reviewSessionPagesList,
  sessionsPage,
  sessionState,
  shareSessionPagesList,
  sncFilters,
  signalr: signalrReducer,
  spatialModel,
  splatModel,
  structureModel,
  tenantConfigPage,
  tenantForms,
  tenantSessionsPage,
  threedeeModel,
  topNavBar,
  towerSideProfile,
  tracking,
  twoDLineDrawing,
  users,
  utilityInfrastructure,
  vqaPolicy,
  whiteboard,
  workflows,
  splatModel,
}
