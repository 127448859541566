import { memo, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { callApiAsync } from '@tabeeb/state/sagas/watchRequest'
import { trainingMaterialTitleFormatter } from '@tabeeb/shared/utils/text'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'

import { markContentTrainingMaterialAsRead } from '../../actions'
import { useTrainingMaterial } from '../../hooks'

import ArticleContent, { ArticleContentSkeleton } from '../ArticleContent'
import { ArticleTitleSkeleton } from '../ArticleName'

const ContentTrainingMaterialDialog = ({ open, contentTrainingMaterial, onClose, onUpdated }) => {
  const dispatch = useDispatch()

  const onError = useCallback(() => {
    dispatch(
      onAddErrorNotification({ message: trainingMaterialTitleFormatter.format('Failed to load training material') })
    )
    onClose()
  }, [dispatch, onClose])

  const { loading, trainingMaterial } = useTrainingMaterial({
    id: open ? contentTrainingMaterial?.TrainingMaterial?.Id : undefined,
    onError,
  })

  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false)
  const markAsRead = useCallback(async () => {
    try {
      setIsSubmitInProgress(true)
      await callApiAsync(
        markContentTrainingMaterialAsRead.request({ ContentTrainingMaterialId: contentTrainingMaterial.Id })
      )
      onClose({ updated: true })
      onUpdated()
    } catch (error) {
      dispatch(
        onAddErrorNotification({
          message: trainingMaterialTitleFormatter.format('Failed to mark training material as read'),
        })
      )
    } finally {
      setIsSubmitInProgress(false)
    }
  }, [contentTrainingMaterial?.Id, onClose, onUpdated, dispatch])

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='md'
      PaperProps={{ sx: { height: '100%' } }}
      TransitionProps={{ unmountOnExit: true }}
      onClose={onClose}
    >
      <DialogTitle display='flex' alignItems='center' sx={{ paddingBottom: 0 }}>
        {loading ? <ArticleTitleSkeleton /> : trainingMaterial?.Name}
      </DialogTitle>
      <DialogContent sx={{ paddingInline: 0, display: 'flex', flexDirection: 'column' }}>
        {loading ? <ArticleContentSkeleton /> : <ArticleContent content={trainingMaterial?.Content} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {contentTrainingMaterial?.Assigned && !contentTrainingMaterial?.Read && (
          <Button
            disabled={loading || isSubmitInProgress}
            size='small'
            color='primary'
            variant='contained'
            onClick={markAsRead}
          >
            Mark as read
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

ContentTrainingMaterialDialog.propTypes = {
  contentTrainingMaterial: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Assigned: PropTypes.bool.isRequired,
    Read: PropTypes.bool.isRequired,
    TrainingMaterial: PropTypes.shape({
      Id: PropTypes.number.isRequired,
    }),
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
}

export default memo(ContentTrainingMaterialDialog)
