import { combineActions, handleActions } from 'redux-actions'
import * as contentActions from '../actions'

const defaultState = {
  contentId: 0,
  createdOn: new Date(),
  timestampCreatedOn: 0,
  name: '',
  description: '',
  documentId: 0,
  expirationDate: null,
  grade: 0,
  hasExtendedRight: false,
  ownerId: 0,
  presenterId: 0,
  programId: null,
  sessionMode: 0,
  parentContentId: null,
  tenantId: 0,
  lastCallStartTime: null,
}

export default handleActions(
  {
    [contentActions.resetContentState](state, action) {
      return defaultState
    },
    [contentActions.renameContentSuccess](state, action) {
      const {
        payload: { description },
      } = action
      return { ...state, description, name: description }
    },
    [contentActions.getContentSuccess](state, action) {
      const { data } = action.response

      return {
        ...state,
        contentId: data.Id,
        createdOn: new Date(data.CreatedOn),
        timestampCreatedOn: Date.parse(data.CreatedOn),
        name: data.Description,
        description: data.Description,
        documentId: data.Document.Id,
        descriptionCreatedUsingConvention: data.DescriptionCreatedUsingConvention,
        expirationDate: null,
        grade: 0,
        hasExtendedRight: false,
        ownerId: data.Owner.Id,
        presenterId: data.PresenterId || data.Owner.Id,
        programId: data.ProgramId,
        sessionMode: data.SessionMode,
        pointCloudModelStatus: data.PointCloudModelStatus,
        parentContentId: data.CopyOfId,
        structureModelStatus: data.StructureModelStatus,
        tenantId: data.TenantId,
        lastCallStartTime: data.LastVideoCallInfo?.StartCallTime,
      }
    },
    [contentActions.onUpdateContentState](state, action) {
      return { ...state, ...action.payload }
    },
    [contentActions.onSetContentId](state, action) {
      return { ...state, contentId: action.payload }
    },
    [contentActions.setPresenter](state, { payload }) {
      return { ...state, presenterId: payload }
    },
    [contentActions.setLastCallStartTime](state, { payload }) {
      return { ...state, lastCallStartTime: payload }
    },
  },
  defaultState
)
