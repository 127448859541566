import { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Field } from 'formik'

import { AIObjectPropertyType } from '@tabeeb/enums'
import { FormikCheckbox, FormikDateTimePicker } from '@tabeeb/modules/shared/forms'
import { renderTimeViewClock } from '@mui/x-date-pickers-pro'
import TextFieldComponent from '../TextFieldComponent'

const UniqueAIObjectPropertyField = ({ name, label, disabled = false, aiObjectPropertyType, ...props }) => {
  const aiObjectPropertyTypeProps = useMemo(
    () => ({
      [AIObjectPropertyType.Json]: { type: 'string', component: TextFieldComponent, multiline: true },
      [AIObjectPropertyType.String]: { type: 'string', component: TextFieldComponent, multiline: true },
      [AIObjectPropertyType.IntNumber]: { type: 'number', component: TextFieldComponent },
      [AIObjectPropertyType.DoubleNumber]: {
        type: 'number',
        component: TextFieldComponent,
        inputProps: {
          step: 0.1,
        },
      },
      [AIObjectPropertyType.DateTime]: {
        component: FormikDateTimePicker,
        minDate: moment.utc('0001-01-01T00:00:00.000'), // "0001-01-01T00:00:00Z"
        maxDate: moment.utc('9999-12-31T23:59:59.999'), // "9999-12-31T23:59:59Z"
        disablePast: false,
        disableFuture: false,
        ampm: false,
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
      [AIObjectPropertyType.Dropdown]: {},
      [AIObjectPropertyType.Checkbox]: {},
      [AIObjectPropertyType.Url]: { type: 'url', component: TextFieldComponent },
      [AIObjectPropertyType.Boolean]: { component: FormikCheckbox },
      [AIObjectPropertyType.Latitude]: {
        type: 'number',
        component: TextFieldComponent,
        inputProps: {
          step: 0.1,
        },
      },
      [AIObjectPropertyType.Longitude]: {
        type: 'number',
        component: TextFieldComponent,
        inputProps: {
          step: 0.1,
        },
      },
    }),
    []
  )

  return (
    <Field
      {...props}
      name={name}
      label={label}
      disabled={disabled}
      {...aiObjectPropertyTypeProps[aiObjectPropertyType]}
      variant='outlined'
      fullWidth
      sx={{ marginBottom: 1 }}
    />
  )
}

UniqueAIObjectPropertyField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  aiObjectPropertyType: PropTypes.oneOf(Object.values(AIObjectPropertyType)),
}

export default UniqueAIObjectPropertyField
