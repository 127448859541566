import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { callApiAsync } from '@tabeeb/state/sagas/watchRequest'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

import { deleteAnnouncement, getAnnouncements, updateAnnouncementStatus, updateAnnouncementSeverity } from '../actions'

const EMPTY_SET = []
const EMPTY_SEARCH = ''

export default ({ skip = 0, take = 50, search = EMPTY_SEARCH } = {}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [announcements, setAnnouncements] = useState(EMPTY_SET)

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        setLoading(true)
        const {
          response: {
            data: { Items: fetchedAnnouncements, TotalCount: totalCount },
          },
        } = await callApiAsync(getAnnouncements.request({ skip, take }))

        setAnnouncements(fetchedAnnouncements)
        setTotal(totalCount)
        setLoading(false)
      } catch (e) {
        dispatch(onAddErrorNotification({ message: 'Failed to load announcements' }))
      } finally {
        setLoading(false)
      }
    }

    fetchAnnouncements()
  }, [dispatch, skip, take])

  const onDeleteAnnouncement = useCallback(
    async ({ id }) => {
      try {
        await callApiAsync(deleteAnnouncement.request({ id }))

        setAnnouncements((prevExpressions) => prevExpressions.filter((expression) => expression.Id !== id))

        dispatch(onAddSuccessNotification({ message: 'Announcement successfully deleted' }))
      } catch {
        dispatch(onAddErrorNotification({ message: 'Failed to delete announcement' }))
      }
    },
    [dispatch]
  )

  const onUpdateAnnouncementStatus = useCallback(
    async ({ id, status }) => {
      try {
        await callApiAsync(updateAnnouncementStatus.request({ id, status }))

        setAnnouncements((prevExpressions) =>
          prevExpressions.map((prevExpression) =>
            prevExpression.Id === id ? { ...prevExpression, Status: status } : prevExpression
          )
        )

        dispatch(onAddSuccessNotification({ message: 'Announcement successfully updated' }))
      } catch {
        dispatch(onAddErrorNotification({ message: 'Failed to update announcement' }))
      }
    },
    [dispatch]
  )

  const onUpdateAnnouncementSeverity = useCallback(
    async ({ id, severity }) => {
      try {
        await callApiAsync(updateAnnouncementSeverity.request({ id, severity }))

        setAnnouncements((prevExpressions) =>
          prevExpressions.map((prevExpression) =>
            prevExpression.Id === id ? { ...prevExpression, Severity: severity } : prevExpression
          )
        )

        dispatch(onAddSuccessNotification({ message: 'Announcement successfully updated' }))
      } catch {
        dispatch(onAddErrorNotification({ message: 'Failed to update announcement' }))
      }
    },
    [dispatch]
  )

  return {
    loading,
    announcements,
    total,
    onDeleteAnnouncement,
    onUpdateAnnouncementStatus,
    onUpdateAnnouncementSeverity,
  }
}
