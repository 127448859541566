import _ from 'lodash'

import circleFit from 'circle-fit'

import convertLangLatToUTM from '@tabeeb/modules/shared/utils/gpsCoordinates/convertLangLatToUTM'
import { calculateAzimuth } from '@tabeeb/modules/compass/services'

const getXmlValue = (data, path) => {
  return data.getElementsByTagName(path)[0].textContent
}

export const parseImagesMetadataFile = ({ text }) => {
  const parser = new DOMParser()
  const data = parser.parseFromString(text, 'text/xml')
  const imageDataNodes = data.getElementsByTagName('Photo')

  const images = _.map(imageDataNodes, (imageDataNode) => {
    const gpsDataNode = imageDataNode.getElementsByTagName('GPS')[0]

    const name = imageDataNode.getElementsByTagName('ImagePath')[0].textContent

    let tag = _.last(name.split('/'))

    const djiTagIndex = tag.indexOf('DJI')

    tag = tag
      .slice(djiTagIndex, djiTagIndex + 8)
      .replace('.', '')
      .replace('_', '')

    const image = {
      id: parseInt(getXmlValue(imageDataNode, 'Id'), 10),
      name,
      tag,
      altitude: parseFloat(getXmlValue(gpsDataNode, 'Altitude')),
      latitude: parseFloat(getXmlValue(gpsDataNode, 'Latitude')),
      longitude: parseFloat(getXmlValue(gpsDataNode, 'Longitude')),
    }

    return image
  })

  const imagesFitCircle = circleFit.compute(
    images.map((image) => {
      return convertLangLatToUTM(image.latitude, image.longitude)
    })
  )

  const { center, distances, radius } = imagesFitCircle

  for (let i = 0; i < images.length; i++) {
    const image = images[i]

    image.azimuth = calculateAzimuth(center, image.longitude, image.latitude)
    image.distance = radius + distances[i]
  }

  return images
}
