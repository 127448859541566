import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Box,
  Grow,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Slide,
} from '@mui/material'
import { OpenWithOutlined, RotateLeftOutlined, FormatShapesOutlined } from '@mui/icons-material'

import Portal from '../Portal'

import KeyIcon from './KeyIcon'
import { TransformationModeDisplayNames, TransformationModes } from '../../constants'
import { getTransformationState } from '../../selectors'
import { setTransformationMode, setTransformationSettings } from '../../actions'

const modes = [
  { mode: TransformationModes.TRANSLATE, key: 'T', keyCode: 84, icon: <OpenWithOutlined htmlColor='#ffffff' /> },
  { mode: TransformationModes.ROTATE, key: 'R', keyCode: 82, icon: <RotateLeftOutlined htmlColor='#ffffff' /> },
  { mode: TransformationModes.SCALE, key: 'S', keyCode: 83, icon: <FormatShapesOutlined htmlColor='#ffffff' /> },
]

const TransformationControlsHelper = ({ container }) => {
  const dispatch = useDispatch()

  const { enabled, mode: selectedMode } = useSelector(getTransformationState)

  const onSetMode = useCallback(
    (mode) => {
      dispatch(setTransformationMode(mode))
    },
    [dispatch]
  )

  useEffect(() => {
    const onKeyDown = (e) => {
      const selectedMode = modes.find((mode) => mode.keyCode === e.keyCode)?.mode
      if (!selectedMode) {
        return
      }

      onSetMode(selectedMode)
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onSetMode])

  return (
    <Portal container={container}>
      <Slide in={enabled}>
        <Box
          position='absolute'
          display='flex'
          alignItems='center'
          justifyContent='center'
          top={0}
          left={50}
          right={50}
          p={1}
          color='#ffffff'
        >
          <Box display='flex' alignItems='center' p={1} pt={0.5} borderRadius={1} bgcolor='rgba(28, 28, 28, 0.4)'>
            {modes.map(({ mode, key, icon }) => (
              <ListItemButton
                key={key}
                sx={{
                  maxWidth: 240,
                  width: 240,
                  outline: mode === selectedMode ? '1.5px solid #ffffff' : 'none',
                }}
                onClick={() => onSetMode(mode)}
              >
                <ListItemIcon>
                  <KeyIcon name={key} />
                </ListItemIcon>
                <ListItemText primary={TransformationModeDisplayNames[mode]} secondary={`Press ${key} to enable`} />
                <ListItemSecondaryAction>{icon}</ListItemSecondaryAction>
              </ListItemButton>
            ))}
          </Box>
        </Box>
      </Slide>
    </Portal>
  )
}

TransformationControlsHelper.propTypes = {
  container: PropTypes.instanceOf(Element).isRequired,
}

export default memo(TransformationControlsHelper)
