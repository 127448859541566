import { put, takeLatest, select, all } from 'redux-saga/effects'

import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'
import { contentStateSelectors } from '@tabeeb/modules/shared/content'
import { notifySystem } from '@tabeeb/modules/shared/content/services/notificationService'
import { callNotificationActions, callNotificationSelectors } from '@tabeeb/modules/callNotification'
import { accountSelectors } from '@tabeeb/modules/account'
import { whiteboardActions } from '@tabeeb/modules/whiteboard'
import { openCallPreviewDialog } from '@tabeeb/modules/presentation/actions/conference'
import { enterRoom } from '@tabeeb/modules/whiteboard/services'

function* onCallStarted(action) {
  const [model] = action.payload

  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  if (currentUserId === model.PresenterId) {
    return
  }

  const sessionId = yield select(contentStateSelectors.getContentId)

  if (sessionId === model.SessionId) {
    yield enterRoom(model.SessionId, model.PresenterId)
    return
  }

  yield put(callNotificationActions.addCallNotification(model))
}

function* onCallEnded(action) {
  const [model] = action.payload
  const { sessionId } = model

  const notification = yield select((state) => callNotificationSelectors.callNotificationSelector(state, { sessionId }))
  if (!notification) {
    return
  }

  yield put(callNotificationActions.hideCallNotification(sessionId))
}

function* showSystemCallNotification(action) {
  const notificationOptions = action.payload
  notifySystem(notificationOptions)
}

function* handleHideStartCallNotification(action) {
  const sessionId = yield select((state) => contentStateSelectors.getContentId(state))
  const notification = yield select((state) => callNotificationSelectors.callNotificationSelector(state, { sessionId }))

  if (notification && sessionId === notification.sessionId) {
    yield put(callNotificationActions.hideCallNotification(sessionId))
  }
}

function* callNotificationSaga() {
  yield all([
    takeLatest(callNotificationActions.showSystemCallNotification, showSystemCallNotification),
    takeLatest([whiteboardActions.openMobileJoinCallDialog, openCallPreviewDialog], handleHideStartCallNotification),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onCallStarted, onCallStarted),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onCallEnded, onCallEnded),
  ])
}

export default callNotificationSaga
